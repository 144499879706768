import hoteld1 from "../assets/img/hotel/hotel.png";
import hoteld2 from "../assets/img/hotel/hotel4.webp"
import hoteld3 from "../assets/img/hotel/hotel5.webp"
import hoteld4 from "../assets/img/kota/room1.jpeg";
import hoteld5 from "../assets/img/kota/room2.jpeg";
import hoteld6 from "../assets/img/kota/room3.jpeg";
import living from "../assets/img/hotel/bed-1.png";
import dining from "../assets/img/hotel/dining.jpeg";
import attraction from "../assets/img/hotel/attraction.webp";
import summerOffer from "../assets/img/events/event-menu-2.png";
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import HotelTab from "./Hotel/HotelTab";
import MapImage from "../assets/img/hotel/map.png";
import SearchBox from "./Hotel/SearchBox";
import ProfileCard from "./Hotel/ProfileCard";
import { Link } from "react-router-dom";
import EcoLogo from "../assets/img/hotel/eco.png";
import nearby1 from "../assets/img/kota/nearby1.webp";
import nearby2 from "../assets/img/kota/nearby2.webp";
import nearby3 from "../assets/img/kota/nearby3.webp";
import nearby4 from "../assets/img/kota/nearby4.webp";
import {
  diningContent,
  LivingContent,
  EventBanquetOne,
  EventBanquetTwo,
  restroBar,
} from "./content/EcoHotel";
import Popup from "./helper/Popup";
import FormStepper from "./Hotel/EventForm/FormStepper";

const livingDetails = [
  {
    tab: "Overview",
    content: (
      <p>
        Experience unparallel comfort at The Eco Satva, Kota, where each of our
        63 luxurious rooms are designed to blend modern amenities with
        eco-friendly practices. Our rooms offers a perfect blend of luxury,
        comfort, eco-consciousness and provide a peaceful retreat with spacious
        layouts, soothing colors and premium amenities, designed with a modern
        aesthetic, these rooms feature elegant furnishings and a cozy ambience,
        making them ideal for both business and leisure travelers.
        <br />
        Wake up to the sound of birds, with large windows that bring in natural
        light and offer stunning views of the surroundings.
      </p>
    ),
  },
  {
    tab: "Standard Double Room",
    content: LivingContent(
      `The Double Standard Rooms at The Eco Satva Kota offer a perfect blend of luxury, comfort, and eco-consciousness. Designed with a modern aesthetic, these rooms feature elegant furnishings and a cozy ambiance, making them ideal for both business and leisure travelers. `,
      4999
    ),
  },
  // {
  //   tab: "Guest Room",
  //   content: LivingContent(
  //     `Discover comfort and sustainability in our elegantly designed Guest Room, perfect for a restful stay. With eco-friendly features and modern amenities, our guest rooms offer a cozy, luxurious experience that prioritizes both your comfort and the environment.`
  //   ),
  // },

  // {
  //   tab: "Executive Suit",
  //   content: LivingContent(
  //     "Our Executive Rooms are the most spacious and feature-rich accommodations in EcoValue Kochi, perfect for guests staying longer or those seeking a more luxurious experience. These rooms are ideal for families or business travelers who need both space and functionality.",
  //     10000
  //   ),
  // },
];

const diningDetails = [
  {
    tab: "Overview",
    content: (
      <p>
        The restaurant and bar at The Eco Satva Kota seamlessly blend luxury
        with eco-friendliness.
        <br />
        The Rooftop Restobar offers a chic open-to-sky dining experience with
        eco-conscious design with a relaxed ambiance.
        <br />
        The Main Restaurant provides a refined indoor setting with a focus on
        Indian and international cuisine, prioritizing organic, locally sourced
        ingredients
      </p>
    ),
  },
  {
    tab: "GG's",
    content: restroBar,
  },
  {
    tab: "SAHAR",
    content: diningContent,
  },
];

function HotelDetails() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const divRefs = useRef([]);

  const eventDetails = [
    {
      tab: "Overview",
      content:
        "The Eco Satva Kota features two elegant banquet halls, designed to accommodate a range of events while maintaining the hotel’s commitment to sustainability and luxury. These versatile spaces are perfect for hosting corporate functions, social gatherings, and grand celebrations, with state-of-the-art amenities that ensure every event is seamless and memorable.",
    },
    {
      tab: "Banquet 1",
      content: <EventBanquetOne setPopupOpen={handleOpenPopup} />,
    },
    {
      tab: "Banquet 2",
      content: <EventBanquetTwo setPopupOpen={handleOpenPopup} />,
    },
  ];

  const sections = [
    {
      id: 1,
      label: "Living",
      image: living,
      type: "living",
      data: livingDetails,
    },
    {
      id: 2,
      label: "Dining",
      image: dining,
      type: "dining",
      data: diningDetails,
    },
    {
      id: 3,
      label: "Event",
      image: attraction,
      type: "event",
      data: eventDetails,
    },
    { id: 3, label: "Nearby Attractions" },
  ];

  // Function to scroll to a specific div
  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <divasa className="next-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-right"></i>
      </divasa>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-left"></i>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: false,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const setting2 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: false,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    customPaging: (i) => <div className="custom-dot"></div>,
    dotsClass: "custom-slick-dots custom-dots",
    responsive: [
      {
        breakpoint: 1024, // Large devices, like laptops and desktops
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Medium devices, like tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Small devices, like smartphones
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviewSetting = {
    dots: true,
    infinite: true,
    autoplay: true,
    autodelayspeed: 1500,
    swipeToSlide: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    customPaging: (i) => <div className="custom-dot"></div>,
    dotsClass: "custom-slick-dots custom-dots",
  };
  return (
    <>
      {isPopupOpen && (
        <Popup title="Event Booking Form" onClose={handleClosePopup}>
          <FormStepper></FormStepper>
        </Popup>
      )}
      {/* <TopBanner /> */}
      <section className="hotel-details-area section-bg-2 pat-120 pab-50">
        <div className="container">
          <SearchBox />

          <div className="row g-4 pat-20">
            <div className="col-xl-9 col-lg-9">
              <div className="details-left-wrapper">
                <div className="details-contents bg-white radius-10">
                  <div className="details-contents-header">
                    <div className="main">
                      <Slider
                        asNavFor={nav2}
                        ref={(slider) => (sliderRef1 = slider)}
                        arrows={false}
                        className="slider slide-show"
                      >
                        <div className="bg-image radius-5">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld1}
                            alt=""
                          />
                        </div>

                        <div className="bg-image radius-5">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld2}
                            alt=""
                          />
                        </div>

                        <div className="bg-image radius-5">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld3}
                            alt=""
                          />
                        </div>

                        <div className="bg-image">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld4}
                            alt=""
                          />
                        </div>
                        <div className="bg-image">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld5}
                            alt=""
                          />
                        </div>
                        <div className="bg-image">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld6}
                            alt=""
                          />
                        </div>
                      </Slider>
                      <Slider
                        asNavFor={nav1}
                        ref={(slider) => (sliderRef2 = slider)}
                        slidesToShow={3}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={false}
                        {...settings}
                        className="global-slick-init attraction-slider nav-style-one nav-color-one"
                      >
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld1}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld2}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld3}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld4}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld5}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld6}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>

                  <div className="hotel-view-contents">
                    <div className="hotel-view-contents-header">
                      {/* <span className="hotel-view-contents-review">
                        {" "}
                        <i className="las la-star"></i> 4.5{" "}
                        <span className="hotel-view-contents-review-count">
                          {" "}
                          (380){" "}
                        </span>{" "}
                      </span> */}
                      <div className="d-flex align-items-center justify-content-between par-20">
                        <h3 className="fw-bold ">The Eco Satva Kota</h3>
                        <img
                          src={EcoLogo}
                          alt="Eco Logo"
                          width={100}
                          height={80}
                        />
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-map-marker-alt"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          Indraprastha Industrial Area
                        </span>
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-phone-alt"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          +91 9650350602
                        </span>
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-at"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          revenuehead@ecohotels.in
                        </span>
                      </div>
                    </div>
                    <div className="hotel-view-contents-middle">
                      <div className="hotel-view-contents-flex">
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-parking"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Parking{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-wifi"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Wifi{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-coffee"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Breakfast{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-quidditch"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Room Service{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-swimming-pool"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Pool{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-receipt"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Reception{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-dumbbell"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Gym{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="hotel-view-contents-bottom">
                      <div className="pat-30 par-20">
                        <h5 className="fw-bold label">About</h5>
                        <p className="pat-5">
                          Nestled in the heart of Kota, near City Mall and next
                          to Resonance, The Eco Satva Kota is a premium
                          mid-range hotel that combines modern luxury with a
                          deep commitment to sustainability. Boasting 63
                          elegantly designed rooms, each equipped with
                          energy-saving systems and eco-friendly amenities, the
                          hotel offers an unparalleled experience for guests who
                          value comfort and environmental consciousness.
                          <br />
                          <br />
                          At The Eco Satva Kota, every detail is designed to
                          deliver a luxurious stay that also protects the
                          planet, making it the perfect destination for the
                          modern, mindful traveller.
                        </p>
                      </div>
                      {/* Top Navigation */}
                      <ul className="nav nav-pills my-4 gap-4 d-flex justify-content-sm-center">
                        {sections?.map((item, index) => (
                          <li className="nav-item" key={item?.id}>
                            <div className="">
                              <span
                                className={`hotel-btn-wrapper hotel-btn ${
                                  index === 0 ? "active" : ""
                                }`}
                                onClick={() => scrollToDiv(index)}
                              >
                                {item?.label}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                      {sections
                        .map((section, index) => (
                          <div
                            key={section.id}
                            ref={(el) => (divRefs.current[index] = el)}
                          >
                            <HotelTab
                              title={section.label}
                              image={section.image}
                              type={section.type}
                              tab={true}
                              data={section.data}
                              downloadMenuBtn={section.id === 2}
                              brochureBtn={section.id === 3}
                            />
                          </div>
                        ))
                        ?.slice(0, 3)}

                      {/* <HotelTab title="Dining" image={dining} type={"dining"} />
                      <HotelTab title="Events" image={attraction} /> */}
                      <section
                        className="attraction-area"
                        ref={(el) => (divRefs.current[3] = el)}
                      >
                        <div className="">
                          <div className="row my-5">
                            <h4
                              className="hotel-view-contents-bottom-title"
                              style={{
                                fontSize: "18px",
                              }}
                            >
                              Nearby Attractions
                            </h4>
                            <div className="col-12 mt-3">
                              <Slider
                                className="global-slick-init attraction-slider nav-style-one nav-color-two"
                                {...setting2}
                              >
                                <div>
                                  <Link to="/NearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{ width: "90%" }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby1} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Garadia Temple
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Rajasthan
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/NearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby2} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            City Palace
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Rajasthan
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/NearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby3} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Jagmandir Palace
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Rajasthan
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/NearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby4} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Kota Barrage
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Rajasthan
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  {/* <div class="details-contents-tab mt-5">
                    <ul class="tabs details-tab details-tab-border">
                      <li class="active" data-tab="about">
                        {" "}
                        About{" "}
                      </li>
                      <li data-tab="reviews" class="">
                        {" "}
                        Reviews{" "}
                      </li>
                    </ul>
                    <div id="about" class="tab-content-item active">
                      <div class="about-tab-contents">
                        <p class="about-tab-contents-para">
                          {" "}
                          One morning, when Gregor Samsa woke from troubled
                          dreams, he found himself transformed in his bed into a
                          horrible vermin. He lay on his armour-like back.{" "}
                        </p>
                        <p class="about-tab-contents-para mt-4">
                          {" "}
                          He lifted his head a little he could see his brown
                          belly, slightly domed and divided by arches into stiff
                          sections. The bedding was hardly able to cover it and
                          seemed ready to slide off any moment. His many legs,
                          pitifully thin compared with the size of the rest of
                          him.{" "}
                        </p>
                        <p class="about-tab-contents-para mt-4">
                          So many legs pitifully thin compared with the size of
                          the rest of him waved about helplessly as he looked
                          What's happened to me.{" "}
                        </p>
                      </div>
                    </div>
                    <div id="reviews" class="tab-content-item">
                      <div class="review-tab-contents">
                        <div class="review-tab-contents-single">
                          <div class="rating-wrap">
                            <div class="ratings">
                              <span class="hide-rating"></span>
                              <span class="show-rating"></span>
                            </div>
                            <p>
                              {" "}
                              <span class="total-ratings">(167)</span>
                            </p>
                          </div>
                          <p class="about-review-para mt-3">
                            {" "}
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed a egestas leo. Aliquam ut ante lobortis
                            tellus cursus pellentesque. Praesent feugiat tellus
                            quis aliquet{" "}
                          </p>
                          <div class="review-tab-contents-author mt-4">
                            <h4 class="review-tab-contents-author-name">
                              {" "}
                              Sandra M. Hurt{" "}
                            </h4>
                            <p class="review-tab-contents-author-para mt-2">
                              {" "}
                              TrustPilot{" "}
                            </p>
                          </div>
                        </div>
                        <div class="review-tab-contents-single">
                          <div class="rating-wrap">
                            <div class="ratings">
                              <span class="hide-rating"></span>
                              <span class="show-rating"></span>
                            </div>
                            <p>
                              {" "}
                              <span class="total-ratings">(236)</span>
                            </p>
                          </div>
                          <p class="about-review-para mt-3">
                            {" "}
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed a egestas leo. Aliquam ut ante lobortis
                            tellus cursus pellentesque. Praesent feugiat tellus
                            quis aliquet{" "}
                          </p>
                          <div class="review-tab-contents-author mt-4">
                            <h4 class="review-tab-contents-author-name">
                              {" "}
                              Robert Fox{" "}
                            </h4>
                            <p class="review-tab-contents-author-para mt-2">
                              {" "}
                              Designer{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* <div className="hotel-view bg-white radius-20 mt-4">
                  <div className="hotel-view-flex p-4">
                    <div className="hotel-view-contents">
                      <div className="review-tab-contents">
                        <div className="review-tab-contents-single">
                          <h4 className="details-section-title">
                            <a href="javascript:void(0)">Reviews</a>
                          </h4>
                          <div className="rating-wrap mt-4">
                            <div className="ratings">
                              <span className="hide-rating"></span>
                              <span className="show-rating"></span>
                            </div>
                            <p>
                              {" "}
                              <span className="total-ratings">(167)</span>
                            </p>
                          </div>
                          <p className="about-review-para mt-3">
                            {" "}
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed a egestas leo. Aliquam ut ante lobortis
                            tellus cursus pellentesque. Praesent feugiat tellus
                            quis aliquet{" "}
                          </p>
                          <div className="review-tab-contents-author mt-4">
                            <h4 className="review-tab-contents-author-name">
                              {" "}
                              Sandra M. Hurt{" "}
                            </h4>
                            <p className="review-tab-contents-author-para mt-2">
                              {" "}
                              TrustPilot{" "}
                            </p>
                          </div>
                        </div>
                        <div className="review-tab-contents-single">
                          <div className="rating-wrap">
                            <div className="ratings">
                              <span className="hide-rating"></span>
                              <span className="show-rating"></span>
                            </div>
                            <p>
                              {" "}
                              <span className="total-ratings">(236)</span>
                            </p>
                          </div>
                          <p className="about-review-para mt-3">
                            {" "}
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed a egestas leo. Aliquam ut ante lobortis
                            tellus cursus pellentesque. Praesent feugiat tellus
                            quis aliquet{" "}
                          </p>
                          <div className="review-tab-contents-author mt-4">
                            <h4 className="review-tab-contents-author-name">
                              {" "}
                              Robert Fox{" "}
                            </h4>
                            <p className="review-tab-contents-author-para mt-2">
                              {" "}
                              Designer{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">
              <div className="sticky-top">
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                  <div className="details-sidebar">
                    <div className="details-sidebar-dropdown custom-form">
                      <form action="#">
                        <div className="single-input">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check In{" "}
                          </label>
                          <input
                            className="form--control date-picker flatpickr-input"
                            type="hidden"
                            placeholder="Check in"
                            value="06-08-2024 12:00"
                          />
                          {/* <input
                            className="form--control date-picker form-control input"
                            placeholder="Check in"
                            tabindex="0"
                            type="text"
                            readonly="readonly"
                          /> */}
                        </div>
                        <div className="single-input mt-3">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check Out{" "}
                          </label>
                          <input
                            className="form--control date-picker flatpickr-input"
                            type="hidden"
                            placeholder="Check out"
                            value="07-08-2024 12:00"
                          />
                          {/* <input
                            className="form--control date-picker form-control input"
                            placeholder="Check out"
                            tabindex="0"
                            type="text"
                            readonly="readonly"
                          /> */}
                        </div>
                      </form>
                    </div>
                    <div className="details-sidebar-quantity pt-4">
                      <div className="details-sidebar-quantity-flex">
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Guest{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="5"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Children{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="3"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Room{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="2"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper mt-4">
                      <Link
                        to="/Checkout"
                        className="cmn-btn btn-bg-1 btn-small"
                      >
                        {" "}
                        Reserve Now{" "}
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <div className="hotel-details-widget widget bg-white radius-10">
                  <div className="hotel-view">
                    <div className="hotel-view-thumb hotel-view-grid-thumb bg-image">
                      <img className="img-fluid" src={hoteld1} />
                    </div>
                    <div className="hotel-view-contents">
                      <div className="hotel-view-contents-header">
                        <span className="hotel-view-contents-review">
                          {" "}
                          <i className="las la-star"></i> 4.5{" "}
                          <span className="hotel-view-contents-review-count">
                            {" "}
                            (380){" "}
                          </span>{" "}
                        </span>
                        <h3 className="hotel-view-contents-title">
                          {" "}
                          King Suite Room{" "}
                        </h3>
                        <div className="hotel-view-contents-location mt-2">
                          <span className="hotel-view-contents-location-icon">
                            {" "}
                            <i className="las la-map-marker-alt"></i>{" "}
                          </span>
                          <span className="hotel-view-contents-location-para">
                            {" "}
                            4140 Parker Rd. Allentown, New Mexico 31134{" "}
                          </span>
                        </div>
                      </div>
                      <div className="hotel-view-contents-middle">
                        <div className="hotel-view-contents-flex">
                          <div
                            className="hotel-view-contents-icon myTooltip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Car Parking"
                          >
                            <i className="las la-parking"></i>
                          </div>
                          <div
                            className="hotel-view-contents-icon myTooltip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Free Wifi"
                          >
                            <i className="las la-wifi"></i>
                          </div>
                          <div
                            className="hotel-view-contents-icon myTooltip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Free Breakfast"
                          >
                            <i className="las la-coffee"></i>
                          </div>
                          <div
                            className="hotel-view-contents-icon myTooltip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Home Service"
                          >
                            <i className="las la-quidditch"></i>
                          </div>
                          <div
                            className="hotel-view-contents-icon myTooltip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Swimming Pool"
                          >
                            <i className="las la-swimming-pool"></i>
                          </div>
                          <div
                            className="hotel-view-contents-icon myTooltip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Reception"
                          >
                            <i className="las la-receipt"></i>
                          </div>
                          <div
                            className="hotel-view-contents-icon myTooltip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Gym"
                          >
                            <i className="las la-dumbbell"></i>
                          </div>
                        </div>
                      </div>
                      <div className="hotel-view-contents-bottom">
                        <div className="hotel-view-contents-bottom-flex">
                          <div className="hotel-view-contents-bottom-contents">
                            <h4 className="hotel-view-contents-bottom-title">
                              {" "}
                              ₹12000 <sub>/Night</sub>{" "}
                            </h4>
                            <p className="hotel-view-contents-bottom-para">
                              {" "}
                              (4 Nights, 2 Rooms, 4 Persons){" "}
                            </p>
                          </div>
                          <div className="btn-wrapper">
                            <a
                              href="javascript:void(0)"
                              className="cmn-btn btn-bg-1 btn-small"
                            >
                              {" "}
                              Reserve Now{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <p className="label">View in map</p>
                <img src={MapImage} alt="map" />

                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10 my-4">
                  <p className="label mb-2 fw-bold">
                    Celebrate Sustainably, Host Elegantly – Book Your
                    Eco-Friendly Event at The Eco Satva
                  </p>
                  <div className="details-sidebar">
                    <div className="details-sidebar-offer-thumb">
                      <img src={summerOffer} alt="" />
                    </div>
                    <div className="btn-wrapper mt-3">
                      <Link to="/events" className="cmn-btn btn-bg-1 w-100">
                        {" "}
                        Book Now{" "}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <div className="bg-white radius-20 p-4 pb-5">
                    <p className="label fw-bold">Review</p>
                    <Slider
                      arrows={false}
                      className="slider slide-show"
                      {...reviewSetting}
                    >
                      {[1, 2, 3, 4]?.map((i) => (
                        <ProfileCard key={i} />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HotelDetails;
