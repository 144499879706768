import error1 from '../assets/img/single-page/404.png';

import TopBanner from './Banner';

    
function Error() {
  return (
    <>
    <div className="error-area pat-200 pab-100 section-bg-3">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="error-wrapper center-text">
                        <div className="error-wrapper-thumb">
                            <img src={error1} alt="img" />
                        </div>
                        <div className="error-contents mat-60">
                            <h2 className="error-contents-title"> Page Not Found </h2>
                            <div className="btn-wrapper mat-25">
                                <a href="/" className="cmn-btn btn-bg-1"> Back To Home </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  );
}

export default Error;
