import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";

function HotelTab({
  image,
  type,
  title,
  tab,
  data,
  brochureBtn,
  downloadMenuBtn,
}) {
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(0); // Track active tab index
  const sliderRef1 = useRef(null); // Ref for the main slider

  // Handle Tab Click
  const handleTabClick = (index) => {
    setActiveTab(index); // Set the active tab index
    if (sliderRef1.current) {
      sliderRef1.current.slickGoTo(index); // Scroll the slider to the selected index
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: false,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div className="my-5">
      {/* Content Section */}
      <div className="row mb-4">
        <div className="col-md-3">
          <img
            src={image}
            className="radius-50 w-100"
            alt="Room"
            style={{
              borderRadius: `${
                type === "living"
                  ? "140px"
                  : type === "dining"
                  ? "20px"
                  : "20px"
              }`,
              height: "350px",
              objectFit: "cover",
            }}
          />
        </div>
        <div
          className={`col-md-9 d-flex flex-column justify-content-between pt-1 par-20`}
        >
          {/* Content Area */}
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <h4
                className="hotel-view-contents-bottom-title mt-2"
                style={{
                  fontSize: "18px",
                }}
              >
                {title || ""}
              </h4>
              {brochureBtn && (
                <button className="text-gold bg-transparent border-0">
                  Download Brochure
                  <span className="p-1 mt-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1.6em"
                      width="1.6em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z"></path>
                    </svg>
                  </span>
                </button>
              )}

              {downloadMenuBtn && (
                <button className="text-gold bg-transparent border-0">
                  Download Menu
                  <span className="p-1 mt-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1.6em"
                      width="1.6em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z"></path>
                    </svg>
                  </span>
                </button>
              )}
            </div>
            <div className="my-1">
              <Slider
                ref={sliderRef1} // Attach the ref to the Slider component
                {...settings}
              >
                {data?.map((item, index) => (
                  <div key={index}>{item.content}</div>
                ))}
              </Slider>
            </div>
          </div>

          {/* Bottom Tabs for Content Switching */}
          <div
            className="hide-scrollbar"
            style={{
              width: "100%",
              overflowX: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "3rem",
              }}
            >
              {tab &&
                data?.map((item, index) => (
                  <div key={index} className="tab-content">
                    <li
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        overflow: "hidden",
                      }}
                    >
                      <span
                        style={{
                          cursor: "pointer",
                          color: `${
                            activeTab === index ? "#B8860B" : "#8B4513"
                          }`,
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => handleTabClick(index)}
                      >
                        {item.tab}
                      </span>
                    </li>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotelTab;
