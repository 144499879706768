import React, { useState } from 'react';
import FormUserDetails from './FormUserDetails';
import FormPersonalDetails from './FormPersonalDetails';
import Confirm from './Confirm';
import Success from './Success';
import Button from '@mui/material/Button';

const UserForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    location: '',
    eventDate: '',
    eventTime: '',
    selectedGuest: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    corporateQuery: '',
    selectedEvent: '',
    requiredRoom: '',
    rooms: ''
  });
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  // Proceed to next step
  const nextStep = () => setStep(step + 1);

  // Go back to previous step
  const prevStep = () => setStep(step - 1);

  // Handle field changes
  const handleChange = input => e => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const { location, eventDate, eventTime, selectedGuest, firstName, lastName, email, phone, companyName, corporateQuery, requiredRoom, selectedEvent, rooms } = formData;
  const values = { location, eventDate, eventTime, selectedGuest, firstName, lastName, email, phone, companyName, corporateQuery, requiredRoom, selectedEvent, rooms };

  // Button to reopen the popup
  const reopenPopupButton = (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <Button variant="contained" color="primary" onClick={() => setIsPopupOpen(true)}>
        Reopen Form
      </Button>
    </div>
  );

  const UserForm = ({ handleClosePopup }) => {
    return (
      <div className="popup-overlay">
        <div className="popup-content">
          <button onClick={handleClosePopup}>Close</button>
          {/* Your form content */}
        </div>
      </div>
    );
  };
  
  // If popup is closed, show the button to reopen it
  if (!isPopupOpen) {
    return reopenPopupButton;
  }

  // Render the form steps within the popup
  switch (step) {
    case 1:
      return (
        <FormUserDetails
          nextStep={nextStep}
          handleChange={handleChange}
          values={values}
          setIsPopupOpen={setIsPopupOpen}
        />
      );
    case 2:
      return (
        <FormPersonalDetails
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={values}
          setIsPopupOpen={setIsPopupOpen}
        />
      );
    case 3:
      return (
        <Confirm 
          nextStep={nextStep}
          prevStep={prevStep}
          values={values}
          setIsPopupOpen={setIsPopupOpen}
        />
      );
      case 4:
        return (
          <Success 
            setIsPopupOpen={setIsPopupOpen}  // Ensure setIsPopupOpen is passed here
          />
        );
  }
};

export default UserForm;
