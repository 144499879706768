import React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { Stack, Stepper, Step, StepLabel } from '@mui/material';

const theme = createTheme();

const steps = ['Event Details', 'Personal Details', 'Summary', 'Success'];

const Confirm = ({ values, nextStep, prevStep, setIsPopupOpen }) => {
  const { location, eventDate, eventTime, selectedGuest, firstName, lastName, email, phone, companyName, corporateQuery, requiredRoom, selectedEvent, rooms } = values;

  // Closes the dialog by calling the parent-provided function
  const handleClose = () => {
    setIsPopupOpen(false); // Notify parent to close the dialog
  };

  const continueHandler = (e) => {
    e.preventDefault();
    // PROCESS FORM //
    nextStep();
  };

  const backHandler = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={true} // Always open as we manage its visibility in UserForm
        fullWidth
        maxWidth='sm'
        onClose={handleClose} // Close the dialog when clicking outside or pressing escape
      >
        <AppBar position="static">
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" style={{ color: 'white' }}>Summary Details</Typography>
            <Button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose} // Close dialog when close button is clicked
              style={{ color: 'white', background: 'transparent', border: 'none', fontSize: '24px' }}
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </Toolbar>
        </AppBar>

        {/* Stepper */}
        <Stepper activeStep={2} alternativeLabel style={{marginTop:'20px'}}>
          {steps.map((label, index) => (
            <Step key={index} completed={2 > index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Typography variant="h6" style={{ marginLeft: '40px', marginTop: '10px', marginBottom: '0px', fontWeight: 600,fontSize:"1rem" }}>Event Details</Typography>
        <List style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', marginLeft: '25px', marginTop: '0px', marginBottom: '0px', marginRight: '25px' }}>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Event Location" secondary={location} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Event Type" secondary={selectedEvent} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Event Date" secondary={eventDate} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Event Time" secondary={eventTime} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Number of Guests" secondary={selectedGuest} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
        </List>

        <Typography variant="h6" style={{ marginLeft: '40px', marginTop: '0px', marginBottom: '0px', fontWeight: 600, fontSize:"1rem" }}>Personal Details</Typography>
        <List style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', marginLeft: '25px', marginTop: '0px', marginBottom: '0px', marginRight: '25px' }}>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="First Name" secondary={firstName} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem  style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Last Name" secondary={lastName} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Email" secondary={email} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Phone" secondary={phone} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Is this a corporate query?" secondary={corporateQuery ? 'Yes' : 'No'} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Company Name" secondary={companyName} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Do you need Rooms?" secondary={requiredRoom ? 'Yes' : 'No'} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
          <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
            <ListItemText primary="Number of Rooms" secondary={rooms} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
          </ListItem>
        </List>

        <Stack direction="row" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', paddingRight: '60px', paddingTop: '0px', paddingBottom: '20px', justifyContent: 'flex-end', gap: '0.5rem' }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={backHandler}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={continueHandler}
          >
            Confirm & Continue
          </Button>
        </Stack>
      </Dialog>
    </ThemeProvider>
  );
};

export default Confirm;
