import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function First() {
  const [event, setevent] = React.useState('');

  const handleChange = (event) => {
    setevent(event.target.value);
  };

  const [numberofguest, setguest] = React.useState('');

  const handleChange1 = (numberofguest) => {
    setguest(numberofguest.target.value);
  };
  return (
    <>
    <Box
      component="form"
      sx={{ '& .MuiTextField-root': {width: '42%', float:'left', mt: 4, mb: 0, mr: 2, ml: 2 } }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          required
          id="outlined-required"
          label="First Name"
          size="small"
        />
        <TextField
          required
          id="outlined-required"
          label="Last Name"
          size="small"
        />
        <TextField
          required
          id="outlined-number"
          label="Email"
          type="Email"
          size="small"
        />
        <TextField
          required
          id="outlined-number"
          label="Phone"
          type="Phone"
          size="small"
        />
        <TextField
          required
          id="outlined-date"
          label="Check In"
          type="date"
          size="small"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        <TextField
          required
          id="outlined-date"
          label="Check Out"
          type="date"
          size="small"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        <FormControl sx={{  mt: 4, mb: 4, mr: 2, ml: 2 , minWidth: '42%' }} size="small">
      <InputLabel id="demo-simple-select-autowidth-label">Number of Guest</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={numberofguest}
        label="Number of Guest"
        onChange={handleChange1}
      >
        <MenuItem value="">
        <em>Select Number of Guest</em>
        </MenuItem>
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select>
    </FormControl>
    <FormControl sx={{  mt: 4, mb: 4, mr: 2, ml: 2 , minWidth: '42%' }} size="small">
      <InputLabel id="demo-simple-select-autowidth-label">Event Type</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={event}
        label="Event Type"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select>
    </FormControl>
      </div>
      
    </Box>
    
    </>
    
  );
}
