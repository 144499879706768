// axiosConfig.js

import axios from "axios";
// import setHeader from "./axiosHeader";

// axios.defaults.headers.common["Authorization"] = setHeader();
const instance = axios.create({
  baseURL: `https://api-public.eazypms.com/api/v1`,
});

export default instance;
