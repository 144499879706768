import React, { useState } from "react";
import ArrowBack from "../../assets/img/hotel/arrow_back_ios.svg";
import Popup from "../helper/Popup";
import { useSelector } from "react-redux";
import axios from "../../lib/axiosConfig";
import { calculateDaysBetweenDates } from "../helper/BetweenDates";
import Subscription from "../helper/Subscription";
import { CheckCircleOutline, TramOutlined } from "@mui/icons-material";
import {
  DialogActions,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const CheckoutForm = () => {
  // Handle Popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [serviceId, setServiceId] = useState();
  const [guestDetails, setGuestDetails] = useState({
    prefix: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    country: "",
    address1: "",
    zip: "",
  });

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  // -------------- HANDLE USE-SELECTOR ---------------- //
  const bookingData = useSelector((state) => state.booking);
  const searchData = useSelector((state) => state.search);

  const calculateDaysBetween = calculateDaysBetweenDates(
    new Date(searchData?.checkIn),
    new Date(searchData?.checkOut)
  );

  const roomPrice = parseFloat(bookingData?.price);
  const priceWithAddons = bookingData?.addOns?.items?.reduce(
    (acc, curr) => acc + curr.price,
    roomPrice
  );

  const submitDetails = {
    noOfAdults: searchData?.noOfAdults,
    noOfChildren: searchData?.noOfChild,
    bookerDetails: {
      email: guestDetails.email,
      firstName: guestDetails.firstName,
      lastName: guestDetails.lastName,
      phone: guestDetails.mobile,
      countryCode: "+91",
      isBookerGuest: true,
    },
    mainGuestsDetails: {
      email: guestDetails.email,
      firstName: guestDetails.firstName,
      lastName: guestDetails.lastName,
      phone: guestDetails.mobile,
      address: guestDetails.address1,
      dob: "",
      age: "",
      countryCode: "+91",
      city: "",
      country: "India",
      state: "",
      zipCode: guestDetails.zip,
      gstNo: "",
      nationality: "India",
    },
    attachmentIds: ["6694d9cd9d320c0019f2e8af"],
    startDateTime: searchData.checkIn,
    endDateTime: searchData.checkOut,
    source: "Website",
    serviceRequests: [
      {
        noOfRooms: 1,
        roomTypeId: "66bec329d347910019acff5e",
        noOfGuest: {
          adult: searchData.noOfAdults,
          children: searchData.noOfChild,
        },
      },
    ],
  };

  const handleOnChange = (key, value) => {
    setGuestDetails((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmitForm = async () => {
    setIsLoading(TramOutlined);
    try {
      const res = await axios({
        method: "POST",
        url: `/public/service-requests/room-type/${searchData?.location?.companyId}/${searchData?.location?.propertyId}/block`,
        data: submitDetails,
        headers: {
          applicationId: "60879d4572e7c85fe46ac1d6",
        },
      });

      if (res) {
        setServiceId(res?.data?.data[0]?.parentServiceRequestId);
        setIsLoading(false);
        handleOpenPopup();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert("Something went wrong");
    }
  };
  const ConfirmBooking = async () => {
    setIsLoading(true);

    try {
      const res = await axios({
        method: "PATCH",
        url: `/public/service-requests/room-type/${searchData?.location?.companyId}/${searchData?.location?.propertyId}/${serviceId}/confirm`,
        data: submitDetails,
      });

      if (res) {
        setShowConfirm(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert("Something went wrong");
    }
  };

  return (
    <div className="bg-gray pab-100">
      <div className="container pat-120 pe-auto">
        <div
          className="d-flex gap-3"
          onClick={() => {
            window.history.back();
          }}
        >
          <div>
            <img src={ArrowBack} alt="Back" />
          </div>
          <h5 className="card-title fw-bold">Checkout</h5>
        </div>
        <div className="row pat-20">
          <div className="col-md-8 ">
            <div className="card p-4 radius-20 details-contents">
              <div className="card-body">
                <form>
                  <h5 className="fw-600">Guest Details</h5>
                  <div className="row my-3">
                    <div className="form-group col-md-3">
                      <label htmlFor="inputPrefix">Prefix *</label>
                      <select
                        id="inputPrefix"
                        className="form-control"
                        onChange={(e) =>
                          handleOnChange("prefix", e.target.value)
                        }
                      >
                        <option value={"Mr."}>Mr.</option>
                        <option value={"Mrs."}>Mrs.</option>
                        <option value={"Miss"}>Miss</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputFirstName">First Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputFirstName"
                        placeholder="First Name"
                        onChange={(e) =>
                          handleOnChange("firstName", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group col-md-5">
                      <label htmlFor="inputLastName">Last Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputLastName"
                        placeholder="Last Name"
                        onChange={(e) =>
                          handleOnChange("lastName", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputMobile">Mobile *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputMobile"
                        placeholder="Mobile"
                        onChange={(e) =>
                          handleOnChange("mobile", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputEmail">Email Address *</label>
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        placeholder="Email Address"
                        onChange={(e) =>
                          handleOnChange("email", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <h5 className="mt-5 fw-600">Address</h5>
                  <div className="row my-3">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputCountry">Country *</label>
                      <select
                        id="inputCountry"
                        className="form-control"
                        onChange={(e) =>
                          handleOnChange("country", e.target.value)
                        }
                      >
                        <option>Select Country</option>
                        <option value={"india"}>India</option>
                        <option value="United States">United States</option>
                        <option value="United Kingdom">United Kingdom</option>
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="form-group col-md-8">
                      <label htmlFor="inputAddress1">Address 1 *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputAddress1"
                        placeholder="1234 Main St"
                        onChange={(e) =>
                          handleOnChange("address1", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputZip">Zip / Postal Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputZip"
                        onChange={(e) => handleOnChange("zip", e.target.value)}
                      />
                    </div>
                  </div>
                  <h5 className="mt-5 fw-600">Identity Proof</h5>
                  <div className="row my-3">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputZip">Identity Proof*</label>
                      <input
                        type="file"
                        accept="png/*, jpg/*, jpeg/*, pdf/*, docx/*"
                        className="form-control"
                        id="inputZip"
                      />
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-start align-item-center gap-3">
                    <div className="btn-wrapper ">
                      <button className="cmn-btn btn-bg-1 btn-small">
                        Pay at Hotel
                      </button>
                    </div>
                    <div className="btn-wrapper ">
                      <button
                        className="cmn-btn btn-bg-2 btn-small"
                        onClick={() => window.history.back()}
                      >
                        Pay Now
                      </button>
                    </div>
                  </div> */}

                  <h5 className="mt-5 fw-600">Payment</h5>
                  <div className="form-row mb-3 mt-3">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputCardNumber">Card Number *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputCardNumber"
                        placeholder="Card Number"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputExpiry">
                        Expiration Date (MM/YY) *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputExpiry"
                        placeholder="MM/YY"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputCVV">CVV *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputCVV"
                        placeholder="CVV"
                      />
                    </div>
                  </div>

                  <div className="form-row mb-3">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputNameOnCard">Name on Card *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputNameOnCard"
                        placeholder="eg. John Doe"
                      />
                    </div>
                  </div>

                  <div className="mt-5">
                    <h5 className="mb-4 fw-bold">Policies</h5>

                    <div className="mb-4">
                      <h6 className="label fw-bold">Check-In Policy:</h6>
                      <ul>
                        <li>
                          <strong>Check-In Time:</strong> Our standard check-in
                          time is from 1:00 PM onwards. Early check-ins may be
                          accommodated based on room availability and may incur
                          an additional fee. Please contact us in advance to
                          make arrangements.
                        </li>
                        <li>
                          <strong>Eco-Friendly Practices:</strong> To minimize
                          our environmental impact, we encourage guests to limit
                          their use of single-use plastics and participate in
                          our recycling programs. Please consider bringing your
                          own reusable water bottle.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h6 className="label fw-bold">Check-Out Policy:</h6>
                      <ul>
                        <li>
                          <strong>Check-Out Time:</strong> Our standard
                          check-out time is by 12:00 PM. Late check-outs may be
                          available upon request and may incur an additional
                          fee. Please inform the front desk if you wish to check
                          out later.
                        </li>
                        <li>
                          <strong>Sustainability Reminder:</strong> We
                          appreciate your efforts to reduce your carbon
                          footprint during your stay. Kindly ensure that all
                          lights and electronic devices are turned off before
                          leaving the room.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h6 className="label fw-bold">Cancellation Policy:</h6>
                      <ul>
                        <li>
                          <strong>Cancellation Notice:</strong> Cancellations
                          must be made at least 72 hours prior to the scheduled
                          check-in date to avoid any cancellation fees.
                          Cancellations made within 72 hours of the check-in
                          date will incur a charge equivalent to one night’s
                          stay.
                        </li>
                        <li>
                          <strong>Refunds:</strong> Refunds for cancellations or
                          changes will be processed to the original payment
                          method and may take several business days to reflect
                          in your account.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-3">
                      <p className="text-muted">
                        <strong>Note:</strong> As an eco-conscious hotel, we
                        strive to maintain our environmental policies while
                        offering flexibility to our guests. We appreciate your
                        understanding and cooperation in helping us promote
                        sustainable tourism.
                      </p>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="privacyPolicy"
                        onClick={() => setAgree(true)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="privacyPolicy"
                      >
                        I agree with the Privacy Policy
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-item-center gap-3">
                    <div className="btn-wrapper ">
                      <button className="cmn-btn btn-bg-1 btn-small">
                        Cancel Booking
                      </button>
                    </div>
                    <div className="btn-wrapper ">
                      <button
                        className={`cmn-btn  btn-small ${
                          !agree ? "btn-bg-secondary" : "btn-bg-2"
                        }`}
                        type="button"
                        onClick={handleSubmitForm}
                        disabled={!agree}
                      >
                        Pay at Hotel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div class="checkout-widget checkout-widget-padding widget bg-white radius-10 details-contents">
              <div class="checkout-sidebar">
                <h4 class="checkout-sidebar-title"> Booking Details </h4>
                <div class="checkout-sidebar-contents">
                  <ul class="checkout-flex-list list-style-none checkout-border-top pt-3 mt-3">
                    <li class="list">
                      <span class="regular"> Room Type </span>
                      <span class="strong"> {bookingData?.name}</span>
                    </li>
                    <li class="list">
                      <span class="regular"> Checking In </span>
                      <span class="strong"> {searchData?.checkIn} </span>
                    </li>
                    <li class="list">
                      <span class="regular"> Checking Out </span>
                      <span class="strong"> {searchData?.checkOut} </span>
                    </li>
                    <li class="list">
                      <span class="regular"> Number of Rooms </span>
                      <span class="strong"> 1 </span>
                    </li>
                    {/* <li class="list">
                      <span class="regular"> Total Stay </span>
                      <span class="strong"> 3 Nights, 2 Days </span>
                    </li> */}
                    <li class="list">
                      <span class="regular"> Number of Person </span>
                      <span class="strong">
                        {bookingData?.noOfAdults} Person
                      </span>
                    </li>
                    <li class="list">
                      <span class="regular"> Number of Children </span>
                      <span class="strong">
                        {bookingData?.noOfChild} Children
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="checkout-widget checkout-widget-padding widget bg-white radius-20 mt-4 details-contents">
              <div className="checkout-sidebar ">
                <h4 className="checkout-sidebar-title mb-3">Invoice</h4>
                <hr />

                {/* Room Details */}
                <div className="checkout-sidebar-contents mb-3">
                  <h6 className="fw-bold label">{bookingData?.name}</h6>
                  <p className="text-muted">
                    ({bookingData?.addOns?.groupName})
                  </p>
                  <p>With AddOns :</p>

                  <ul>
                    {bookingData?.addOns?.items?.map((i) => (
                      <li className="d-flex justify-content-between mt-1">
                        <p>{i?.name}</p>
                        <p className="label">₹ {i?.price}</p>
                      </li>
                    ))}
                  </ul>
                  <ul className="checkout-flex-list list-style-none">
                    <li className="d-flex justify-content-between">
                      <span className="label">
                        {calculateDaysBetween} Night
                      </span>
                      <span>
                        <span className="fw-bold label">
                          ₹ {priceWithAddons}
                        </span>
                      </span>
                    </li>
                    <li className="d-flex justify-content-between mt-3">
                      <span className="label">Tax (GST 18%)</span>
                      <span className="fw-bold label">₹ 2160</span>
                    </li>
                  </ul>
                </div>

                {/* Grand Total */}
                <div className="checkout-sidebar-contents py-3 border-top mt-4">
                  <ul className="checkout-flex-list list-style-none">
                    <li className="d-flex justify-content-between">
                      <span className="fw-bold fs-18 label">Total</span>
                      <span className="fw-bold fs-20 label">
                        ₹ {priceWithAddons + 2160}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between">
                      <span className="fs-15 text-muted">(Including Tax)</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            zIndex: 9999,
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      <Dialog
        open={isPopupOpen}
        onClose={handleClosePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Hotel Booking"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please Confirm you hotel booking!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="d-flex justify-content-end align-item-center gap-3">
            <div className="btn-wrapper ">
              <button
                className="cmn-btn btn-bg-1 btn-small"
                onClick={handleClosePopup}
              >
                Cancel Booking
              </button>
            </div>
            <div className="btn-wrapper ">
              <span
                className="cmn-btn btn-bg-2 btn-small"
                onClick={ConfirmBooking}
              >
                Confirm Booking
              </span>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {showConfirm && (
        <Subscription
          title="Hotel Booking"
          onClose={() => setIsPopupOpen(false)}
        >
          <div style={{ textAlign: "center" }}>
            <CheckCircleOutline style={{ fontSize: "100px", color: "green" }} />
            <h5 style={{ fontSize: "24px", color: "green", margin: "40px" }}>
              SUCCESSFULLY BOOKED
            </h5>
          </div>
        </Subscription>
      )}
    </div>
  );
};

export default CheckoutForm;
