import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";

const Subscription = ({ title, onClose, onConfirm, children, isSuccess }) => {
  return (
    <Dialog open onClose={onClose} aria-labelledby="subscription-dialog-title">
      <DialogTitle id="subscription-dialog-title">
        {isSuccess ? "Success!" : title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {isSuccess ? (
          <div style={{ textAlign: "center" }}>
            <CheckCircleIcon
              style={{ color: "green", fontSize: "3rem", marginBottom: "10px" }}
            />
            <Typography variant="h6">Successfully Submitted!</Typography>
          </div>
        ) : (
          children
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Subscription;
