import { Link } from "react-router-dom";
import Hotel1 from "../../assets/img/nagpur/hotel1.jpg";
import Hotel2 from "../../assets/img/nagpur/hotel2.png";
import Hotel3 from "../../assets/img/nagpur/hotel3.png";
import Hotel4 from "../../assets/img/nagpur/hotel2.jpg";
// import Hotel4 from "../../assets/img/nagpur/hotel4.png";

export const nagpurHotelImageList = [
  { id: 1, image: Hotel1 },
  { id: 2, image: Hotel4 },
  { id: 3, image: Hotel2 },
  { id: 4, image: Hotel3 },
  // { id: 4, image: Hotel4 },
];

export const ggContent = (
  <>
    <p>
      GG’s Rooftop Restrobar is a standout feature of EcoXpress Satva, offering
      a unique "dine and dance" concept. Set atop the hotel, the restrobar
      provides stunning views of Nagpur city and a vibrant atmosphere for
      evening relaxation. Guests can enjoy a variety of multi-cuisine options
      along with refreshing beverages in a lively, open-air environment.
    </p>
    <h6 className="label mt-2">Timings</h6>
    <div className="row align-items-center px-md-3 mt-2">
      <div className="col-2 col-md-2">
        <p className="label">Everyday:</p>
      </div>
      <div className="col-6 col-md-6 ">
        <p className="text-gold">7:00 AM – 10:30 AM</p>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content-end btn-wrapper par-20 mt-3 mt-md-0 order-last order-md-0">
        <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link>
      </div>
    </div>
  </>
);

export const saharContent = (
  <>
    <p>
      This multi-cuisine restaurant is dedicated to offering a wholesome,
      healthy dining experience with a focus on Satvic (plant-based) meals. The
      menu features a mix of local and international cuisines, all prepared
      using fresh, seasonal ingredients. Sahar emphasizes balanced meals that
      nurture the body and mind, making it a perfect dining choice for
      health-conscious travelers. The peaceful ambiance adds to the calming
      dining experience.
    </p>
    <br />
    <div className="d-flex justify-content-between align-items-end">
      <div>
        <h6 className="label">Timings</h6>
        <p className="label mt-2">
          Everyday: <span className="text-gold">7:00 PM – 10:30 AM</span>
        </p>
      </div>
      <div className="btn-wrapper par-20">
        <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link>
      </div>
    </div>
  </>
);
