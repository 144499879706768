import React from "react";
import bannerMobile from "../../assets/img/hotel/banner-mobile.png";

const CommonBanner = ({ banner }) => {
  return (
    <div className="">
      {/* Banner Section */}
      <div className="">
        <div className="">
          <img src={banner} alt="Hotel" className="w-100" />
        </div>
      </div>
    </div>
  );
};

export default CommonBanner;
