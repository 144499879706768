import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const theme = createTheme();

const steps = ['Event Details', 'Personal Details', 'Summary', 'Success'];

const FormUserDetails = ({ values, handleChange, nextStep, setIsPopupOpen }) => {
  const [selectedEvent, setSelectedEvent] = useState(values.selectedEvent || '');
  const [selectedGuest, setSelectedGuest] = useState(values.selectedGuest || '');
  const [activeStep, setActiveStep] = useState(0); // Track current step
  const [errors, setErrors] = useState({}); // For validation errors

  useEffect(() => {
    setSelectedEvent(values.selectedEvent || '');
  }, [values.selectedEvent]);

  useEffect(() => {
    setSelectedGuest(values.selectedGuest || '');
  }, [values.selectedGuest]);

  const handleClose = () => {
    setIsPopupOpen(false); // Notify parent to close the dialog
  };

  const validate = () => {
    let isValid = true;
    let newErrors = {};

    if (!values.location) {
      newErrors.location = 'Event location is required';
      isValid = false;
    }
    if (!selectedEvent) {
      newErrors.selectedEvent = 'Event type is required';
      isValid = false;
    }
    if (!values.eventDate) {
      newErrors.eventDate = 'Event date is required';
      isValid = false;
    }
    if (!values.eventTime) {
      newErrors.eventTime = 'Event time is required';
      isValid = false;
    }
    if (!selectedGuest) {
      newErrors.selectedGuest = 'Number of guests is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const continueHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      // Update values in parent
      handleChange('selectedGuest')({ target: { value: selectedGuest } });
      handleChange('selectedEvent')({ target: { value: selectedEvent } });
      
      nextStep();
      setActiveStep(prevStep => Math.min(prevStep + 1, steps.length - 1)); // Move to the next step
    }
  };

  const handleEventChange = (e) => {
    setSelectedEvent(e.target.value);
    handleChange('selectedEvent')({ target: { value: e.target.value } }); // Update parent state immediately
  };

  const handleGuestChange = (e) => {
    setSelectedGuest(e.target.value);
    handleChange('selectedGuest')({ target: { value: e.target.value } }); // Update parent state immediately
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={true} // Always open as we manage its visibility in UserForm
        fullWidth
        maxWidth='sm'
        onClose={handleClose} // Ensure the dialog closes when clicking outside or pressing escape
      >
        <AppBar position="static">
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" style={{ color: 'white' }}>
              Enter Event Details
            </Typography>
            <Button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
              style={{ color: 'white', background: 'transparent', border: 'none', fontSize: '24px' }}
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </Toolbar>
        </AppBar>

        <Stepper activeStep={activeStep} alternativeLabel style={{ marginTop: '20px' }}>
          {steps.map((label, index) => (
            <Step key={index} completed={activeStep > index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Stack
          component="form"
          sx={{ mt: 5, display: 'grid', gridTemplateColumns: '1fr 1fr' }}
        >
          <TextField
            sx={{ ml: 5, mt: 0, width: '80%' }}
            placeholder="Enter Your Event Location"
            label="Event Location"
            onChange={handleChange('location')}
            value={values.location}
            required
            error={!!errors.location}
          />
          

          <Select
            sx={{ mr: 5, mt: 0, width: '80%' }}
            value={selectedEvent}
            onChange={handleEventChange}
            displayEmpty
            error={!!errors.selectedEvent}
        
          >
            <MenuItem value="" disabled>Select event type</MenuItem>
            <MenuItem value={'Conference'}>Conference</MenuItem>
            <MenuItem value={'Meeting'}>Meeting</MenuItem>
            <MenuItem value={'Wedding'}>Wedding</MenuItem>
            <MenuItem value={'Celebration'}>Celebration</MenuItem>
          </Select>

          <TextField
            sx={{ ml: 5, mt: 4, width: '80%' }}
            label="Event Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange('eventDate')}
            value={values.eventDate}
            margin="normal"
            error={!!errors.eventDate}
            helperText={errors.eventDate}
          />

          <TextField
            sx={{ mr: 5, mt: 4, width: '80%' }}
            label="Event Time"
            type="time"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange('eventTime')}
            value={values.eventTime}
            margin="normal"
            error={!!errors.eventTime}
            helperText={errors.eventTime}
          />

          <Select
            sx={{ ml: 5, mt: 2, width: '80%' }}
            value={selectedGuest}
            onChange={handleGuestChange}
            displayEmpty
            error={!!errors.selectedGuest}
           
          >
            <MenuItem value="" disabled>Number of Guests</MenuItem>
            <MenuItem value={'Less than 10'}>Less than 10</MenuItem>
            <MenuItem value={'11-25'}>11-25</MenuItem>
            <MenuItem value={'26-50'}>26-50</MenuItem>
            <MenuItem value={'51-100'}>51-100</MenuItem>
          </Select>
        </Stack>

        <Stack direction="row" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '60px', paddingTop: '15px', paddingBottom: '40px' }}>
          <Button
            color="primary"
            variant="contained"
            onClick={continueHandler}
          >
            Continue
          </Button>
        </Stack>
      </Dialog>
    </ThemeProvider>
  );
};

export default FormUserDetails;
