import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Second() {
  return (
    <Box
      component="form"
      sx={{ '& .MuiTextField-root': {minWidth: '42%', mt: 3, mb: 5, mr: 2, ml: 2 } }}
      noValidate
      autoComplete="off"
    >
      <div>
      <div style={{padding: '2rem 1rem 0' }} >
          Activity
        </div>
      <TextField
          required
          id="outlined-date"
          label="Check In"
          type="date"
          size="small"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        <TextField
          required
          id="outlined-date"
          label="Check Out"
          type="date"
          size="small"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
              </div>
          </Box>
  );
}
