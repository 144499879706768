import React from "react";
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { setSearchData } from "../../reducer/searchHotelReducer";

const EventSearch = ({ properties, onChangeSelection, selection }) => {
  //   const dispatch = useDispatch();
  //   function handleSearch() {
  //     dispatch(setSearchData(selection));
  //   }
  return (
    <div className="container">
      <div className="banner-location bg-white radius-5">
        <div className="banner-location-flex">
          <div className="banner-location-single">
            <div className="banner-location-single-flex">
              <div className="banner-location-single-contents">
                <span className="banner-location-single-contents-subtitle">
                  Location
                </span>
                <div className="banner-location-single-contents-dropdown">
                  <div className="select-container">
                    <select
                      className="js-select-2"
                      name="state"
                      onChange={(e) => {
                        const selectedValue = JSON.parse(e.target.value);
                        onChangeSelection("location", selectedValue);
                      }}
                    >
                      <option value={null}>Select Location </option>
                      {/* {properties?.map((item) => (
                        <option
                          key={item?._id}
                          value={JSON.stringify({
                            propertyId: item?._id,
                            companyId: item?.companyId,
                          })}
                        >
                          {item?.name}
                        </option>
                      ))} */}
                      <option>The Eco Satva, Kota</option>
                      <option>EcoXpress Satva, Nagpur</option>
                      <option>EcoValue, Kochi</option>
                    </select>
                    <i className="las la-map-marker-alt select-icon text-black fw-bold"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-location-single">
            <div className="banner-location-single-flex">
              <div className="banner-location-single-contents">
                <span className="banner-location-single-contents-subtitle">
                  Book Date
                </span>
                <div className="banner-location-single-contents-dropdown custom-select">
                  <input
                    id="startDate"
                    class="form-control p-3 label"
                    type="date"
                    onChange={(e) =>
                      onChangeSelection("checkIn", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="banner-location-single">
            <div className="banner-location-single-flex">
              <div className="banner-location-single-contents">
                <span className="banner-location-single-contents-subtitle">
                  End Date
                </span>
                <div className="banner-location-single-contents-dropdown custom-select">
                  <input
                    id="startDate"
                    class="form-control p-3 label"
                    type="date"
                    onChange={(e) =>
                      onChangeSelection("checkOut", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="banner-location-single">
            <div className="banner-location-single-flex">
              <div className="banner-location-single-contents">
                <span className="banner-location-single-contents-subtitle">
                  Function Type
                </span>
                <div className="banner-location-single-contents-dropdown">
                  <div className="select-container">
                    <select
                      className="js-select-2"
                      name="state"
                      onChange={(e) => {
                        const selectedValue = JSON.parse(e.target.value);
                        onChangeSelection("location", selectedValue);
                      }}
                    >
                      <option value={null}>Select Function </option>
                    </select>
                    <i className="select-icon text-black fw-bold">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path></svg>
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-location-single-search">
            <div className="search-suggestions-wrapper">
              <Link
                // onClick={handleSearch}
                to={`/HotelSearch?property=${selection?.location?.propertyId}&company=${selection?.location?.companyId}&checkin=${selection?.checkIn}&checkout=${selection?.checkOut}&person=${selection?.person}&children=${selection?.children}&room=${selection?.room}`}
              >
                <div className="search-click-icon">
                  <i className="las la-search"></i>
                </div>
              </Link>
            </div>
            <div className="search-suggestion-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSearch;
