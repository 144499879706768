import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Summary() {
  return (
    <>
    <Box
      component="form"
      sx={{ '& .MuiTextField-root': { m: 1, width: 'auto' } }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          required
          id="outlined-required"
          label="First Name"
          size="small"
        />
        <TextField
          required
          id="outlined-required"
          label="Last Name"
          size="small"
        />
        <TextField
          required
          id="outlined-number"
          label="Email"
          type="Email"
          size="small"
        />
        <TextField
          required
          id="outlined-number"
          label="Phone"
          type="Phone"
          size="small"
        />
        <TextField
          required
          id="outlined-date"
          label="date"
          type="date"
          size="small"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        
      </div>
    </Box>
    </>
    
  );
}
