import Hotel1 from "../../assets/img/kochi/hotel1.webp";
import Hotel2 from "../../assets/img/kochi/hotel2.webp";
import Hotel3 from "../../assets/img/kochi/hotel3.webp";
import Hotel4 from "../../assets/img/kochi/hotel4.webp";
import Hotel5 from "../../assets/img/kochi/hotel5.webp";
import Hotel6 from "../../assets/img/kochi/hotel6.webp";
import Hotel7 from "../../assets/img/kochi/hotel7.webp";
import Hotel8 from "../../assets/img/kochi/hotel8.webp";

export const LivingContent = (content) => {
  return (
    <>
      <p>{content}</p>

      <div className="pat-10">
        <div className="hotel-view-contents-flex">
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-parking"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-wifi"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-coffee"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-quidditch"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-swimming-pool"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-receipt"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-dumbbell"></i>{" "}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <div>
          <h6 className="label">12000/Night</h6>
        </div>
        <div className="btn-wrapper par-20">
          <button className="cmn-btn btn-bg-1 btn-small">
            Book Now
            <span>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1.6em"
                width="1.6em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z"></path>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export const kochiHotelImageList = [
  { id: 1, image: Hotel1 },
  { id: 2, image: Hotel2 },
  { id: 3, image: Hotel3 },
  { id: 4, image: Hotel4 },
  { id: 5, image: Hotel5 },
  { id: 6, image: Hotel6 },
  { id: 7, image: Hotel7 },
  { id: 8, image: Hotel8 },
];
