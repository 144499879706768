import React, { useEffect, useRef, useState } from "react";
import SearchBox from "../SearchBox";
import Slider from "react-slick";
import hoteld1 from "../../../assets/img/nagpur/nearby/img1.webp";
import hoteld2 from "../../../assets/img/nagpur/nearby/img2.webp";
import hoteld3 from "../../../assets/img/nagpur/nearby/img3.webp";
import hoteld4 from "../../../assets/img/nagpur/nearby/img4.webp";
import food1 from "../../../assets/img/nagpur/nearby/food1.webp";
import food2 from "../../../assets/img/nagpur/nearby/food2.webp";
import food3 from "../../../assets/img/nagpur/nearby/food3.webp";
import food4 from "../../../assets/img/nagpur/nearby/food4.webp";
import culture from "../../../assets/img/nagpur/nearby/culture.webp";
import Deekshabhoomi from "../../../assets/img/nagpur/nearby/deekshabhoomi.webp";
import Ambazari from "../../../assets/img/nagpur/nearby/ambazari.webp";
import Sitabuldi from "../../../assets/img/nagpur/nearby/sitabuli.webp";
import Pench from "../../../assets/img/nagpur/nearby/pench.webp";
import Maharajbagh from "../../../assets/img/nagpur/nearby/maharajbagh.webp";
import Dragon from "../../../assets/img/nagpur/nearby/dragon.webp";
import Raman from "../../../assets/img/nagpur/nearby/raman.webp";
import Zero from "../../../assets/img/nagpur/nearby/zero.webp";
import travel from "../../../assets/img/kochi/nearby/travel.png";
import sunnySvg from "../../../assets/img/hotel/sunny.svg";
import rainySvg from "../../../assets/img/hotel/rainy.svg";
import snowySvg from "../../../assets/img/hotel/weather_snowy.png";
import weather from "../../../assets/img/kota/nearby/weather.png";
import HotelTab from "../HotelTab";

const cultureContent = (
  <>
    <p>
      Nagpur’s culture is a blend of traditional Maharashtrian influences and
      modern cosmopolitan living. The city is known for its literature,
      performing arts, and festivals such as Ganesh Chaturthi and Makar
      Sankranti. Its close association with Dr. B.R. Ambedkar and the Buddhist
      movement is also reflected in cultural landmarks like Deekshabhoomi.
    </p>
    <br />
    <p>
      Nagpur has a population of approximately 2.9 million. It is one of the
      fastest-growing cities in Maharashtra and is recognized as a commercial
      and political center in Vidarbha. The city’s diverse population comprises
      people from different religious and cultural backgrounds, making it a
      vibrant and cosmopolitan place to live.
    </p>
  </>
);

const travelContent = (
  <>
    <ul className="d-flex flex-column gap-4">
      <li>
        <span className="label">By Air:</span>Dr. Babasaheb Ambedkar
        International Airport connects Nagpur to major cities in India and some
        international destinations.
      </li>
      <li>
        <span className="label">By Train:</span>Nagpur is a key junction in
        Central India with excellent rail connectivity to Mumbai, Delhi,
        Kolkata, and Chennai.
      </li>
      <li>
        <span className="label">By Road:</span>Well-connected via the National
        Highway Network (NH 44, NH 6, NH 7), Nagpur is accessible from all
        corners of the country.
      </li>
    </ul>
  </>
);

const NagpurNearbyAttraction = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  const divRefs = useRef([]);

  const sections = [
    {
      id: 2,
      label: "Cuture",
      image: culture,
      type: "dining",
      data: [{ content: cultureContent }],
    },
    {
      id: 3,
      label: "Travel",
      image: travel,
      type: "event",
      data: [{ content: travelContent }],
    },
    { id: 4, label: "Food" },
  ];

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <divasa className="next-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-right"></i>
      </divasa>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-left"></i>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <section className="hotel-details-area section-bg-2 pat-120 pab-50">
      <div className="container">
        <SearchBox />
        <div className="details-contents bg-white radius-10 mt-4">
          <div className="details-contents-header">
            <div className="main">
              <Slider
                asNavFor={nav2}
                ref={(slider) => (sliderRef1 = slider)}
                arrows={false}
                className="slider slide-show"
              >
                <div className="bg-image radius-5">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld1}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld2}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld3}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld4}
                    alt=""
                  />
                </div>
              </Slider>
              <Slider
                asNavFor={nav1}
                ref={(slider) => (sliderRef2 = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                {...settings}
                className="global-slick-init attraction-slider nav-style-one nav-color-one"
              >
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld1}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld2}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld3}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld4}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Slider>
            </div>

            <div className="hotel-view-contents">
              <div className="hotel-view-contents-header">
                <h3 className="hotel-view-contents-title fw-bold">
                  Explore the Orange City,Nagpur
                </h3>
                <div className="mt-2">
                  <span className="fw-bold text-secondary">
                    Best Time to Visit Nagpur
                  </span>
                  <div className="mt-1 d-flex align-items-center gap-3 label">
                    <img src={weather} alt="sunny" /> <span>Oct - Feb</span>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center gap-5">
                  <div>
                    <img src={sunnySvg} alt="sunny" />{" "}
                    <span>Mar - Jun (35°C to 48°C) </span>
                  </div>
                  <div>
                    <img src={rainySvg} alt="sunny" />{" "}
                    <span>Jul - Sep (24°C to 30°C) </span>
                  </div>
                  <div>
                    <img src={snowySvg} alt="sunny" />{" "}
                    <span>Oct - Feb (10°C to 25°C) </span>
                  </div>
                </div>
                <hr />
              </div>

              <div className="hotel-view-contents-bottom">
                <div className="pat-30 par-20">
                  <h5 className="fw-bold label">About</h5>
                  <p className="pat-5">
                    Nagpur, often referred to as the Orange City due to its
                    renowned orange production, is a vibrant metropolis in the
                    heart of India, situated in the state of Maharashtra. Known
                    for its high-quality oranges, it serves as a major trade hub
                    for this citrus fruit. Nagpur also holds historical and
                    cultural significance, with its origins tracing back to the
                    8th century, when it was founded by the Gond Kings before
                    being ruled by the Marathas in the 18th century. The city
                    played a crucial role during India’s colonial era and its
                    freedom struggle, later becoming the site where Dr. B.R.
                    Ambedkar and millions of his followers embraced Buddhism at
                    Deekshabhoomi, marking the beginning of the Dalit Buddhist
                    movement
                    <br />
                    As the Winter Capital of Maharashtra, Nagpur hosts the
                    state’s annual winter legislative session, emphasizing its
                    political importance. Today, it stands as a thriving center
                    for commerce, industry, and culture in Central India,
                    blending its historical roots with modern growth and
                    progress.
                  </p>
                </div>
                {/* Top Navigation */}
                <ul className="nav nav-pills my-4 gap-4 d-flex justify-content-center">
                  {sections?.map((item, index) => (
                    <li className="nav-item" key={item?.id}>
                      <div className="">
                        <span
                          className={`hotel-btn-wrapper hotel-btn ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => scrollToDiv(index)}
                        >
                          {item?.label}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
                <div>
                  {sections
                    .map((section, index) => (
                      <div
                        key={section.id}
                        ref={(el) => (divRefs.current[index] = el)}
                      >
                        <HotelTab
                          title={section.label}
                          image={section.image}
                          type={section.type}
                          data={section.data}
                        />
                      </div>
                    ))
                    ?.slice(0, 2)}
                  <div ref={(el) => (divRefs.current[2] = el)}>
                    <h4
                      className="hotel-view-contents-bottom-title"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      Food in Nagpur
                    </h4>
                    <p>
                      Nagpur offers a blend of Maharashtrian, South Indian, and
                      Central Indian cuisines, making it a food lover's
                      paradise.
                    </p>

                    <div className="pat-10">
                      <div className="row g-4">
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food1}
                                    alt="Appam with Stew"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Saoji Curry</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food2}
                                    alt="Meen Moilee"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Tarri Poha</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food3}
                                    alt="Parotta with Beef Fry"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Zunka Bhakri</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food4}
                                    alt="puttu"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Bhakarwadi</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pat-40">
                      <h4
                        className="hotel-view-contents-bottom-title"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Top Attractions Near EcoXpress Satva Nagpur
                      </h4>

                      <div className="pat-40">
                        <div className="row mb-4">
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={Deekshabhoomi}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Deekshabhoomi</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Deekshabhoomi is a prominent Buddhist
                                    monument where Dr. B.R. Ambedkar embraced
                                    Buddhism in 1956, making it a significant
                                    pilgrimage site. The grand stupa here is a
                                    symbol of equality and peace, attracting
                                    millions of visitors annually, especially
                                    during Dhamma Chakra Pravartan Din.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 6 km</p>
                                  <p>Travel Time: 20 minutes by car</p>
                                </div>
                              </div>
                            </div>

                            <div
                              className="hide-scrollbar"
                              style={{
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "3rem",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Ambazari Lake and Garden</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    A serene lake surrounded by lush gardens,
                                    Ambazari is perfect for a family outing or a
                                    relaxing evening. You can enjoy boating and
                                    nature walks while soaking in the beautiful
                                    sunset views. The gardens are
                                    well-maintained, making it an ideal spot for
                                    picnics.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 9km</p>
                                  <p>Travel Time: 25 minutes by car</p>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={Ambazari}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={Sitabuldi}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Sitabuldi Fort</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    This historic fort, located on a hilltop,
                                    played a key role in Nagpur's colonial
                                    history. It offers panoramic views of the
                                    city and is a quiet spot for history
                                    enthusiasts. The fort is open to the public
                                    on special occasions.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 5 km</p>
                                  <p>Travel Time: 15 minutes by car</p>
                                </div>
                              </div>
                            </div>

                            <div></div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Pench National Park</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Famous for its rich wildlife and being the
                                    inspiration for Rudyard Kipling’s The Jungle
                                    Book, Pench National Park offers thrilling
                                    safari experiences. It is home to tigers,
                                    leopards, deer, and several bird species.
                                    It’s a must-visit for wildlife enthusiasts
                                    and nature lovers.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 90 km</p>
                                  <p>Travel Time: 2 hours by car</p>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <img src={Pench} className="radius-10" alt="Room" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pat-40">
                      <div className="row mb-4">
                        <div className="col-md-4 d-flex justify-content-center">
                          <img
                            src={Maharajbagh}
                            className="radius-10"
                            alt="Room"
                          />
                        </div>
                        <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                          {/* Content Area */}
                          <h6 className="label ">Maharajbagh Zoo</h6>
                          <div>
                            <div>
                              <div>
                                <p>
                                Originally a royal garden, this zoo houses a variety of animals and birds. It’s popular among families and children and is a great place to learn about local wildlife while enjoying the greenery.
                                </p>
                                <br />
                                <p>Distance from Hotel: 4 km</p>
                                <p>Travel Time: 15 minutes by car</p>
                              </div>
                            </div>
                          </div>

                          <div
                            className="hide-scrollbar"
                            style={{
                              width: "100%",
                              overflowX: "auto",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "3rem",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                          {/* Content Area */}
                          <h6 className="label "> Dragon Palace Temple</h6>
                          <div>
                            <div>
                              <div>
                                <p>
                                A stunning Buddhist temple known for its architectural beauty and peaceful ambiance. The temple is set in a lush green area and is an ideal spot for meditation and spiritual retreats.
                                </p>
                                <br />
                                <p>Distance from Hotel: 20km</p>
                                <p>Travel Time: 40 minutes by car</p>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-center">
                          <img
                            src={Dragon}
                            className="radius-10"
                            alt="Room"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-md-4 d-flex justify-content-center">
                          <img
                            src={Raman}
                            className="radius-10"
                            alt="Room"
                          />
                        </div>
                        <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                          {/* Content Area */}
                          <h6 className="label ">The Raman Science Centre</h6>
                          <div>
                            <div>
                              <div>
                                <p>
                                The Raman Science Centre, named after Nobel laureate C.V. Raman, is a hub for science enthusiasts and educational visitors. It is designed to promote scientific temper among students and the general public. The interactive exhibits focus on various scientific principles across physics, chemistry, and biology, making it a fun and engaging experience for all age groups.
                                </p>
                                <br />
                                <p>Distance from Hotel: 3.5 km</p>
                                <p>Travel Time: 10 minutes by car</p>
                              </div>
                            </div>
                          </div>

                          <div></div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                          {/* Content Area */}
                          <h6 className="label "> Zero Mile Marker</h6>
                          <div>
                            <div>
                              <div>
                                <p>
                                A historical landmark representing the geographical center of colonial India, Zero Mile is marked by a stone pillar. It is of great geographical and historical importance, especially for map enthusiasts.
                                </p>
                                <br />
                                <p>Distance from Hotel: 3 km</p>
                                <p>Travel Time: 10 minutes by car</p>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-center">
                          <img src={Zero} className="radius-10" alt="Room" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NagpurNearbyAttraction;
