import React, { useState } from "react";

function PriceFilter() {
  const [minPrice, setMinPrice] = useState(0); // Initial value set to match the min of the range
  const [maxPrice, setMaxPrice] = useState(5000); // Max price of range

  // Function to handle slider change
  const handlePriceChange = (event) => {
    const value = event.target.value;
    setMinPrice(value); // Update minPrice dynamically
  };

  return (
    <div className="single-shop-left bg-white radius-10 mt-4 p-3">
      {/* Price Title */}
      <div className="d-flex justify-content-between">
        <h5 className="title">Price</h5>
      </div>

      {/* Slider */}
      <div className="d-flex align-items-center mt-3 mb-2">
        <input
          type="range"
          className="form-range"
          id="customRange1"
          min={0}
          max={5000}
          value={minPrice}
          onChange={handlePriceChange} // Slider updates the price
        />
      </div>

      {/* Price Range Display */}
      <p className="fw-bold mt-2">
        Price: ₹ {minPrice} - ₹ {maxPrice}
      </p>
    </div>
  );
}

export default PriceFilter;
