import { createSlice } from "@reduxjs/toolkit";

const bookingInitialState = {};

const bookingSlice = createSlice({
  name: "booking",
  initialState: bookingInitialState,
  reducers: {
    setBookingData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setBookingData } = bookingSlice.actions;

export default bookingSlice.reducer;
