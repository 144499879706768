import logo1 from "../assets/img/Eco-logo-white-2.png";
import logo2 from "../assets/img/logo-final.png";
import "../line-awesome.min.css";
import { NavLink, useLocation } from "react-router-dom";
import "../assets/css/custom.css"; // Assume you add active link styles here

function Navigation() {
  const location = useLocation();

  const links = [
    { name: "Home", path: "/" },
    { name: "Hotels", path: "/hotel" },
    { name: "Events", path: "/Events" },
    { name: "Offer & Promotions", path: "/PromotionOffer" },
    { name: "About Us", path: "/AboutUs" },
    {
      name: "Investor Relations",
      path: "https://ehrlindia.in/ehrlindia.in/investor-relations.html",
      external: true,
    },
  ];

  const highlightPaths = [
    "/HotelDetails",
    "/checkout",
    "/KochiHotelDetails",
    "/SindhudurgHotelDetails",
  ];

  const isHighlightedPage = highlightPaths.includes(location.pathname);

  return (
    <header className="header-style-01">
      <nav className="navbar navbar-area white-nav nav-absolute navbar-two navbar-expand-lg">
        <div
          className={`container custom-container-one nav-container ${
            isHighlightedPage ? "bg-white" : ""
          }`}
        >
          {/* Logo Section */}
          <div className="logo-wrapper">
            <NavLink to="/" className="logo">
              <img src={isHighlightedPage ? logo2 : logo1} alt="Logo" />
            </NavLink>
          </div>

          {/* Mobile Menu Button */}
          <div className="responsive-mobile-menu d-lg-none">
            <button
              className={`navbar-toggler ${
                isHighlightedPage ? "bg-secondary" : ""
              }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#hotel_booking_menu"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          {/* Navbar Links */}
          <div className="collapse navbar-collapse" id="hotel_booking_menu">
            <ul className="navbar-nav">
              {links.map((link, index) => (
                <li
                  key={index}
                  className={isHighlightedPage ? "custom-text" : ""}
                >
                  {link.external ? (
                    <a href={link.path} target="_blank" rel="noreferrer">
                      {link.name}
                    </a>
                  ) : (
                    <NavLink
                      to={link.path}
                      className={({ isActive }) =>
                        isActive ? "active-link" : ""
                      }
                    >
                      {link.name}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navigation;
