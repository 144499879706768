import React from "react";
import hotel1 from "../../assets/img/single-page/hotel-grid1.jpg";
import { Link } from "react-router-dom";

const HotelCardTwo = ({
  index,
  type,
  name,
  address,
  caclPrice,
  url,
  storeRoomDetails,
}) => {
  const sendIndex = (i) => {
    storeRoomDetails(i);
  };

  return (
    <div className="col-12">
      <div className="hotel-view bg-white radius-20 p-3">
        <div className="row">
          <div className="col-md-4">
            <img src={hotel1} className="w-100 radius-10" alt="Hotel Room" />
          </div>
          <div className="col-md-8">
            <h3 className="hotel-view-contents-title">{name}</h3>
            <p className="mb-2">
              <strong>{address}</strong>
            </p>
            <p className="mb-4">
              Enjoy garden views from the comfortable interior or the private
              balcony.
              <br />
              <a href="hotel_details.html">Room details</a>
            </p>
            <hr />
            {[1, 2, 3]?.map((i) => (
              <>
                <div
                  key={i}
                  className="d-flex justify-content-between align-items-center mb-3"
                >
                  <div>
                    <p className="mb-0">
                      <strong className="text-black">Room Only Rate</strong>
                    </p>
                    <ul className="list-unstyled">
                      <li>Daily breakfast</li>
                      <li>15% savings on food & soft beverages, laundry</li>
                      <li>One-way airport transfer</li>
                      <li>
                        Business Centre usage for 4 hours (subject to
                        availability)
                      </li>
                    </ul>
                  </div>
                  <div className="text-end">
                    <p className="mb-1 text-black fw-bold">{caclPrice}</p>
                    <p className="mb-0">Per Night</p>
                    <p className="mb-0">Excluding taxes and fees</p>
                    <Link
                      to="/Checkout"
                      className="btn btn-warning mt-2"
                      onClick={() => sendIndex(index)}
                    >
                      Reserve Now
                    </Link>
                    <p className="small mt-1">
                      <a href="login.html">Login for 10% additional savings.</a>
                    </p>
                  </div>
                </div>

                <hr />
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelCardTwo;
