import summerOffer from "../../assets/img/banner/summerOffer.jpg";
import React, { useState, useEffect } from "react";
// import room1 from "../../assets/img/"
import MapImage from "../../assets/img/hotel/map.png";

import EcoLogo from "../../assets/img/hotel/eco.png";
import roombanner from "../../assets/img/hotel/room-banner.png";

import axios from "../../lib/axiosConfig";
import CommonBanner from "../helper/CommonBanner";
import RoomCard from "./RoomCard";
import DestinationLeft from "../DestinationLeft";
import DestinationRight from "../DestinationRight";
import HotelBanner from "./HotelBanner";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBookingData } from "../../reducer/bookingHotelReducer";

function HotelSearch() {
  const [availableRooms, setAvailableRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParam] = useSearchParams();

  const selection = {
    location: {
      propertyId: searchParam.get("property"),
      companyId: searchParam.get("company"),
    },
    startDateTime: searchParam.get("checkin"),
    endDateTime: searchParam.get("checkout"),
    noOfAdult: searchParam.get("person"),
    noOfChild: searchParam.get("children"),
    noOfRooms: searchParam.get("room")
  };

  useEffect(() => {
    const fetchAvailableRooms = async () => {
      try {
        const res = await axios({
          method: "POST",
          url: `/public/room-types/${selection?.location?.companyId}/${selection?.location?.propertyId}/available`,
          data: {
            noOfAdult: parseInt(selection.noOfAdult),
            noOfChild: parseInt(selection.noOfChild),
            startDateTime: selection.startDateTime,
            endDateTime: selection.endDateTime,
          },
        });

        setAvailableRooms(res.data?.data);
        setIsLoading(false);
      } catch (error) {
        throw new Error(error);
        setIsLoading(false);
      }
    };
    fetchAvailableRooms();
  }, [
    selection?.location?.propertyId,
    selection?.location?.companyId,
    selection.noOfAdult,
    selection.noOfChild,
    selection.startDateTime,
    selection.endDateTime,
    searchParam,
  ]);

  const dispatch = useDispatch();

  function storeRoomDetails(index, addOn) {
    const addOnData = availableRooms[index]?.addOns[addOn];
    availableRooms[index].addOns = addOnData;
    dispatch(setBookingData(availableRooms[index]));
  }

  return (
    <>
      {/* <TopBanner /> */}
      <CommonBanner banner={roombanner} />
      <section className="hotel-details-area section-bg-2 pab-50">
        <div className="container">
          <div className="row g-4 pat-20">
            <div className="col-xl-9 col-lg-7">
              <div className="details-left-wrapper">
                <div className="details-contents bg-white radius-10">
                  <div className="details-contents-header"></div>

                  <div className="hotel-view-contents">
                    <div className="hotel-view-contents-header">
                      <div className="d-flex align-items-center justify-content-between par-20">
                        <h3 className="fw-bold ">Eco Hotel</h3>
                        <img src={EcoLogo} alt="Eco Logo" width={130} />
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-map-marker-alt"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                           hotel near city mall and next to Resonance main
                          building
                        </span>
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-phone-alt"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          +91 9650350602
                        </span>
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-at"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          revenuehead@ecohotels.in{" "}
                        </span>
                      </div>

                      <div className="my-5">
                        <h5 className="mb-4 fw-bold">Select a Room</h5>

                        <div className="d-flex gap-4 mb-3">
                          <div className="dropdown">
                            <button
                              className="btn btn-light dropdown-toggle"
                              type="button"
                              id="sortByDropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Sort By
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="sortByDropdown"
                            ></ul>
                          </div>

                          <div className="dropdown">
                            <button
                              className="btn btn-light dropdown-toggle"
                              type="button"
                              id="viewByDropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              View By
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="viewByDropdown"
                            ></ul>
                          </div>
                        </div>
                      </div>

                      <div>
                        {availableRooms?.map((room, index) => (
                          <RoomCard
                            data={room}
                            roomIndex={index}
                            storeRoomDetails={storeRoomDetails}
                          />
                        ))}
                        {/* <RoomCard /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5">
              <div className="sticky-top">
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                  <div className="details-sidebar">
                    <div className="details-sidebar-dropdown custom-form">
                      <form action="#">
                        <div className="single-input">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check In{" "}
                          </label>
                          <input
                            className="form-control date-picker flatpickr-input"
                            type="date"
                            placeholder="Check in"
                            value="06-09-2024"
                          />
                        </div>
                        <div className="single-input mt-3">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check Out{" "}
                          </label>
                          <input
                            className="form-control date-picker flatpickr-input"
                            type="date"
                            placeholder="Check out"
                            value="09-09-2024"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="details-sidebar-quantity pt-4">
                      <div className="details-sidebar-quantity-flex">
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Guest{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="5"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Children{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="3"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Room{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="2"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper mt-4">
                      <a
                        href="checkout.html"
                        className="cmn-btn btn-bg-1 btn-small"
                      >
                        {" "}
                        Reserve Now{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    // <>
    //   <HotelBanner />
    //   <section className="hotel-list-area section-bg-2 pat-50 pab-50">
    //     <div className="container">
    //       <div class="section-title center-text">
    //         <h2 class="title"> Hotel </h2>
    //         <div class="section-title-shapes"> </div>
    //       </div>
    //       <div className="shop-contents-wrapper mt-5">
    //         <div className="shop-icon">
    //           <div className="shop-icon-sidebar">
    //             <i className="las la-bars"></i>
    //           </div>
    //         </div>
    //         <DestinationLeft />
    //         <DestinationRight
    //           reserveType="reserve"
    //           data={availableRooms}
    //           isLoading={isLoading}
    //           url={"/Checkout"}
    //         />
    //       </div>
    //     </div>
    //   </section>
    // </>
  );
}

export default HotelSearch;
