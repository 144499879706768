import React from "react";

const ProfileCard = () => {
  return (
    <div className="card-container">
      <img
        src="https://via.placeholder.com/100"
        alt="Profile"
        className="profile-image"
      />
      <h3 className="profile-name">Amit Patel</h3>
      <p className="profile-description">
        Comfort and Sustainability Combined
        <br />
        Staying at this eco hotel was a fantastic experience. The rooms were
        incredibly comfortable. It's clear that this hotel is committed to
        reducing its carbon footprint, and that made my stay even more
        enjoyable.
      </p>
    </div>
  );
};

export default ProfileCard;
