import React, { useEffect, useRef, useState } from "react";
import SearchBox from "./SearchBox";
import Slider from "react-slick";
import hoteld1 from "../../assets/img/kota/nearby/nearby1.png";
import hoteld2 from "../../assets/img/kota/nearby/nearby2.png";
import hoteld3 from "../../assets/img/kota/nearby/nearby3.png";
import hoteld4 from "../../assets/img/kota/nearby/nearby4.png";
import food1 from "../../assets/img/hotel/food1.png";
import food2 from "../../assets/img/hotel/food2.png";
import food3 from "../../assets/img/hotel/food3.png";
import food4 from "../../assets/img/hotel/food4.png";
import culture from "../../assets/img/hotel/culture.png";
import kotaPalace from "../../assets/img/hotel/barrage.png";
import JagmandirPalace from "../../assets/img/hotel/jagmandir.png";
import gradiaPalace from "../../assets/img/hotel/gradia.png";
import travel from "../../assets/img/hotel/travel.png";
import sunnySvg from "../../assets/img/hotel/sunny.svg";
import rainySvg from "../../assets/img/hotel/rainy.svg";
import snowySvg from "../../assets/img/hotel/weather_snowy.png";
import weather from "../../assets/img/kota/nearby/weather.png";
import city1 from "../../assets/img/hotel/city1.png";
import HotelTab from "./HotelTab";

const cultureContent = (
  <>
    <p>
      Kota is deeply rooted in Rajasthani culture, known for its vibrant
      traditions, colorful festivals, and warm hospitality. The city celebrates
      festivals like Diwali, Holi, and the Dussehra Mela, where you can witness
      local customs, music, and dance. The craftsmanship of Kota Doria sarees is
      also world-famous, showcasing the region’s rich textile heritage.
    </p>
    <br />
    <p>
      The population of Kota is approximately 1.2 million, with a blend of
      traditional and modern lifestyles. Visitors will experience the
      friendliness of the locals and their deep respect for culture and history.
    </p>
  </>
);

const travelContent = (
  <>
    <ul className="d-flex flex-column gap-4">
      <li>
        <span className="label">By Air:</span>The nearest airport is Jaipur
        International Airport, about 250 km from Kota. From the airport, you can
        reach the hotel by taxi or train.
      </li>
      <li>
        <span className="label">By Train:</span>Kota is well-connected by rail,
        with Kota Junction being one of the major railway stations in Rajasthan.
        The station is just 7 km from the hotel, making it easy for guests to
        access the property.
      </li>
      <li>
        <span className="label">By Road:</span>Kota has excellent road
        connectivity, with regular bus services from Jaipur, Udaipur, and Delhi.
        The NH52 highway makes road travel convenient and efficient.
      </li>
    </ul>
  </>
);

const NearbyAttraction = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  const divRefs = useRef([]);

  const sections = [
    {
      id: 2,
      label: "Cuture",
      image: culture,
      type: "dining",
      data: [{ content: cultureContent }],
    },
    {
      id: 3,
      label: "Travel",
      image: travel,
      type: "event",
      data: [{ content: travelContent }],
    },
    { id: 4, label: "Food" },
  ];

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <divasa className="next-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-right"></i>
      </divasa>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-left"></i>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <section className="hotel-details-area section-bg-2 pat-120 pab-50">
      <div className="container">
        <SearchBox />
        <div className="details-contents bg-white radius-10 mt-4">
          <div className="details-contents-header">
            <div className="main">
              <Slider
                asNavFor={nav2}
                ref={(slider) => (sliderRef1 = slider)}
                arrows={false}
                className="slider slide-show"
              >
                <div className="bg-image radius-5">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld1}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld2}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld3}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld4}
                    alt=""
                  />
                </div>
              </Slider>
              <Slider
                asNavFor={nav1}
                ref={(slider) => (sliderRef2 = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                {...settings}
                className="global-slick-init attraction-slider nav-style-one nav-color-one"
              >
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld1}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld2}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld3}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Slider>
            </div>

            <div className="hotel-view-contents">
              <div className="hotel-view-contents-header">
                <h3 className="hotel-view-contents-title fw-bold">
                  Explore the Wonders of Kota, Rajasthan
                </h3>
                <div className="mt-2">
                  <span className="fw-bold text-secondary">
                    Best Time to Visit Kota
                  </span>
                  <div className="mt-1 d-flex align-items-center gap-3 label">
                    <img src={weather} alt="sunny" />{" "}
                    <span>October - March</span>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center gap-5">
                  <div>
                    <img src={sunnySvg} alt="sunny" />{" "}
                    <span>Apr - Jun (35°C to 45°C) </span>
                  </div>
                  <div>
                    <img src={rainySvg} alt="sunny" />{" "}
                    <span>Jul - Sep (25°C to 35°C) </span>
                  </div>
                  <div>
                    <img src={snowySvg} alt="sunny" />{" "}
                    <span>Oct - Mar (10°C to 25°C) </span>
                  </div>
                </div>
                <hr />
              </div>

              <div className="hotel-view-contents-bottom">
                <div className="pat-30 par-20">
                  <h5 className="fw-bold label">About</h5>
                  <p className="pat-5">
                    Kota, a city that blends historical grandeur with natural
                    beauty, offering a unique cultural experience for every
                    traveler. Situated in the heart of Rajasthan, Kota is
                    renowned for its stunning palaces, temples, gardens, and
                    rich heritage. Whether you're an adventure enthusiast or a
                    history buff, the city has something to offer everyone.
                    <br />
                    <br />
                    The ideal time to visit Kota is during the winter months,
                    from October to March, when the weather is pleasant and
                    suitable for exploring. Temperatures during this time range
                    from 10°C to 25°C, making it perfect for sightseeing and
                    outdoor activities. Summers can be extremely hot, with
                    temperatures reaching up to 45°C, so winter is the
                    recommended travel season.
                  </p>
                </div>
                {/* Top Navigation */}
                <ul className="nav nav-pills my-4 gap-4 d-flex justify-content-center">
                  {sections?.map((item, index) => (
                    <li className="nav-item" key={item?.id}>
                      <div className="">
                        <span
                          className={`hotel-btn-wrapper hotel-btn ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => scrollToDiv(index)}
                        >
                          {item?.label}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
                <div>
                  {sections
                    .map((section, index) => (
                      <div
                        key={section.id}
                        ref={(el) => (divRefs.current[index] = el)}
                      >
                        <HotelTab
                          title={section.label}
                          image={section.image}
                          type={section.type}
                          data={section.data}
                        />
                      </div>
                    ))
                    ?.slice(0, 2)}
                  <div ref={(el) => (divRefs.current[2] = el)}>
                    <h4
                      className="hotel-view-contents-bottom-title"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      Food in Kota
                    </h4>
                    <p>
                      Kota offers a delectable array of Rajasthani cuisine, with
                      dishes that reflect the region's rich heritage. Some
                      must-try foods include:
                    </p>

                    <div className="pat-10">
                      <div className="row g-4">
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food1}
                                    alt="Laal Mass"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Laal Mass</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food2}
                                    alt="Dal Baati Churma"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Dal Baati Churma</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food3}
                                    alt="Kota Kachori"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Kota Kachori</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food4}
                                    alt="Ghevar"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Ghevar</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pat-40">
                      <h4
                        className="hotel-view-contents-bottom-title"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Top Attractions Near The Eco Satva Kota
                      </h4>

                      <div className="pat-40">
                        <div className="row mb-4">
                          <div className="col-md-4 d-flex justify-content-center">
                            <img src={city1} className="radius-10" alt="Room" />
                          </div>
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">City Palace</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    This magnificent 17th-century palace
                                    overlooks the Chambal River and is a
                                    stunning example of Rajput architecture. The
                                    palace houses the Rao Madho Singh Museum,
                                    which displays a vast collection of royal
                                    artifacts, manuscripts, and weapons. It’s a
                                    must-visit for history lovers.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 5 km</p>
                                  <p>Travel Time: 15 minutes by car</p>
                                </div>
                              </div>
                            </div>

                            <div
                              className="hide-scrollbar"
                              style={{
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "3rem",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Kota Barrage</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    The Kota Barrage controls the flow of the
                                    Chambal River and is an engineering marvel.
                                    It’s a popular spot to witness the beauty of
                                    the river and watch the sunset.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: km</p>
                                  <p>Travel Time: 15 minutes by car</p>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={kotaPalace}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={JagmandirPalace}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Jagmandir Palace</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Perched on an island in Kishore Sagar Lake,
                                    Jagmandir Palace is a beautiful structure
                                    built by the royals of Kota. The palace
                                    offers a tranquil environment and
                                    picturesque views, especially during sunset.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 5 km</p>
                                  <p>Travel Time: 15 minutes by car</p>
                                </div>
                              </div>
                            </div>

                            <div></div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Garadia Mahadev Temple</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Offering breathtaking views of the Chambal
                                    River from a cliff, this temple is a sacred
                                    spot dedicated to Lord Shiva. The panoramic
                                    view of the river and gorge is perfect for
                                    nature lovers and those seeking serenity.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: km</p>
                                  <p>Travel Time: 15 minutes by car</p>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={gradiaPalace}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NearbyAttraction;
