import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Stack, FormControlLabel, Checkbox } from '@mui/material';

const theme = createTheme();

const steps = ['Event Details', 'Personal Details', 'Summary', 'Success'];

const FormPersonalDetails = ({ values, handleChange, nextStep, prevStep, currentStep, setIsPopupOpen }) => {
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    setIsPopupOpen(false); // Notify parent to close the dialog
  };

  const validate = () => {
    let isValid = true;
    let newErrors = {};

    // Example validation checks
    if (!values.firstName) {
      newErrors.firstName = 'First name is required';
      isValid = false;
    }
    if (!values.lastName) {
      newErrors.lastName = 'Last name is required';
      isValid = false;
    }
    if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
      newErrors.email = 'A valid email is required';
      isValid = false;
    }
    if (!values.phone || !/^\d{10}$/.test(values.phone)) {
      newErrors.phone = 'A valid phone number is required (10 digits)';
      isValid = false;
    }
    if (values.requiredRoom && !values.rooms) {
      newErrors.rooms = 'Number of rooms is required';
      isValid = false;
    }
    if (values.corporateQuery && !values.companyName) {
      newErrors.companyName = 'Company name is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const continueHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      nextStep();
    }
  };

  const backHandler = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={true} // Always open as we manage its visibility in UserForm
        fullWidth
        maxWidth='sm'
        onClose={handleClose} // Ensure the dialog closes when clicking outside or pressing escape
      >
        <AppBar position="static">
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" style={{ color: 'white' }}>
              Enter Personal Details
            </Typography>
            <Button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
              style={{ color: 'white', background: 'transparent', border: 'none', fontSize: '24px' }}
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </Toolbar>
        </AppBar>

        <Stepper activeStep={1} alternativeLabel style={{ marginTop: '20px' }}>
          {steps.map((label, index) => (
            <Step key={index} completed={1 > index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Stack
          component="form"
          sx={{ mt: 5, display: 'grid', gridTemplateColumns: '1fr 1fr' }}
        >
          <TextField
            sx={{ ml: 5, width: '80%' }}
            placeholder="Enter Your First Name"
            label="First Name"
            onChange={handleChange('firstName')}
            value={values.firstName}
            required
            error={!!errors.firstName}
            helperText={errors.firstName}
          />

          <TextField
            sx={{ mt: 0, mr: 5, width: '80%' }}
            placeholder="Enter Your Last Name"
            label="Last Name"
            onChange={handleChange('lastName')}
            value={values.lastName}
            margin="normal"
            error={!!errors.lastName}
            helperText={errors.lastName}
          />

          <TextField
            sx={{ ml: 5, mb: 2, width: '80%' }}
            placeholder="Enter Your Email"
            label="Email"
            onChange={handleChange('email')}
            value={values.email}
            margin="normal"
            error={!!errors.email}
            helperText={errors.email}
          />

          <TextField
            sx={{ mt: 2, mb: 2, mr: 5, width: '80%' }}
            placeholder="Enter Your Phone"
            label="Phone"
            onChange={handleChange('phone')}
            value={values.phone}
            margin="normal"
            error={!!errors.phone}
            helperText={errors.phone}
          />

          <FormControlLabel
            label="Is this a corporate query?"
            sx={{ mt: 1, ml: 4, mb: 2, width: '80%' }}
            control={
              <Checkbox
                checked={values.corporateQuery}
                onChange={handleChange('corporateQuery')}
                name="corporateQuery"
              />
            }
          />

          {values.corporateQuery && (
            <TextField
              sx={{ mt: 1, mr: 5, mb: 2, width: '80%' }}
              placeholder="Enter Company Name"
              label="Company Name"
              onChange={handleChange('companyName')}
              value={values.companyName}
              margin="normal"
              error={!!errors.companyName}
              helperText={errors.companyName}
            />
          )}

          <FormControlLabel
            label="Do you require room?"
            sx={{ mt: 1, ml: 4, mb: 2, width: '80%' }}
            control={
              <Checkbox
                checked={values.requiredRoom}
                onChange={handleChange('requiredRoom')}
                name="requiredRoom"
              />
            }
          />

          {values.requiredRoom && (
            <TextField
              sx={{ mt: 1, mr: 5, mb: 2, width: '80%' }}
              placeholder="Number of Rooms"
              type='number'
              label="No of Rooms"
              onChange={handleChange('rooms')}
              value={values.rooms}
              margin="normal"
              error={!!errors.rooms}
              helperText={errors.rooms}
            />
          )}
        </Stack>

        <Stack direction="row" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', paddingRight: '60px', paddingTop: '15px', paddingBottom: '40px', justifyContent: 'flex-end' }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={backHandler}
            style={{ marginRight: '10px' }}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={continueHandler}
          >
            Continue
          </Button>
        </Stack>
      </Dialog>
    </ThemeProvider>
  );
};

export default FormPersonalDetails;
