import team1 from "../assets/img/single-page/team1.png";
import team2 from "../assets/img/single-page/team2.webp";
import team3 from "../assets/img/single-page/team3.webp";
import team4 from "../assets/img/single-page/team4.jpeg";
import team5 from "../assets/img/single-page/team5.webp";
import team6 from "../assets/img/single-page/team6.png";
import team7 from "../assets/img/single-page/team7.webp";
import Brand from "../assets/brand/brand.webp";
import Brand1 from "../assets/brand/brand1.webp";
import Brand2 from "../assets/brand/brand2.webp";
import Brand3 from "../assets/brand/brand3.webp";
import Brand4 from "../assets/brand/brand4.webp";
import Brand5 from "../assets/brand/brand5.webp";
import Rich1 from "../assets/img/aboutUs/rich1.png";
import Rich2 from "../assets/img/aboutUs/rich2.png";
import Rich3 from "../assets/img/aboutUs/rich3.png";
import Rich4 from "../assets/img/aboutUs/rich4.png";
import Logo1 from "../assets/img/aboutUs/logo1.png";
import Logo2 from "../assets/img/aboutUs/logo2.png";
import Logo3 from "../assets/img/aboutUs/logo3.png";
import Logo4 from "../assets/img/aboutUs/logo4.png";
import Logo5 from "../assets/img/aboutUs/logo5.png";
import Logo6 from "../assets/img/aboutUs/logo6.png";
import { Link } from "react-router-dom";
import TopBanner from "./Banner";
import React, { Suspense, useState } from "react";
import Slider from "react-slick";
import Brands from "./Home/Brands";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <divasa className="next-icon radius-parcent-50" onClick={onClick}>
      <i className="las la-angle-right"></i>
    </divasa>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="prev-icon radius-parcent-50" onClick={onClick}>
      <i className="las la-angle-left"></i>
    </div>
  );
}

const TeamSection = ({ title, teamData }) => {
  const [hoveredMember, setHoveredMember] = useState(null);

  return (
    <section className="team-area pat-50 pab-50">
      <div className="container">
        <div className="section-title center-text">
          <h2 className="title">{title}</h2>
          <div className="section-title-shapes"></div>
          {title === "Board Members" && (
            <p className="section-para pt-4 text-start">
              Eco Hotels India brings together the credibility of international
              industry experts to run the show. Together with their world
              experience and local knowledge, they provide the right foundation
              for an idea whose time has arrived.
            </p>
          )}
        </div>
        <div className="row g-5 mt-2 p-5 justify-content-center">
          {teamData.map((member, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center"
              onMouseEnter={() => setHoveredMember(index)}
              onMouseLeave={() => setHoveredMember(null)}
            >
              <div className="single-team center-text">
                <div className="single-team-thumb position-relative">
                  <a href="javascript:void(0)">
                    <img
                      src={member.image}
                      alt="img"
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                        height: "auto",
                        transition: "opacity 0.3s",
                        opacity: hoveredMember === index ? 0.8 : 1,
                      }}
                    />
                  </a>
                  {hoveredMember === index && (
                    <div className="popup-description position-absolute text-start start-0 bg-white p-3 shadow-lg">
                      <h5 className="label fw-bold">{member.name}</h5>
                      <p className="single-team-contents-subtitle text-gold mb-1">
                        {member.title}
                      </p>
                      <p>{member.description}</p>
                    </div>
                  )}
                </div>
                <div className="single-team-contents">
                  <h4 className="single-team-contents-title">{member.name}</h4>
                  <p className="single-team-contents-subtitle">
                    {member.title}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

function AboutUs() {
  const brands = [
    {
      name: "Eco Hotel",
      image: Brand1,
    },
    {
      name: "The Eco Satva",
      image: Brand2,
    },
    {
      name: "EcoValue",
      image: Brand3,
    },
    {
      name: "Eco Xpress (Satva)",
      image: Brand4,
    },
  ];

  const members = {
    boardMembers: [
      {
        name: "Mr. Vinod K Tripathi",
        title: "Executive Chairman and Director",
        image: team1,
        description:
          "Comes with over 40 years of experience in the taxation, finance, administration, textiles, and wind turbine sector along w with other areas. Formerly, the Commissioner of Income Tax, with his last posting in Mumbai. He was the Managing Director at National Textiles Corporation (Maharashtra South and Gujarat) and was President and group director at Reliance Capital Limited for 14 years. He also worked as an Auditor with the office of the Comptroller and Auditor General of India. As an academic, he taught Political Science at Ewing Christian College, Allahabad. He has a keen interest in sports and social activities and he is a poet too and has been awarded the prestigious Sahitya Academy award, by Maharashtra Urdu Sahitya Academy.",
      },
      {
        name: "Mr. Suchit Punnose",
        title: "Non Executive Director",
        image: team2,
        description:
          "Suchit is the founder and CEO of Red Ribbon Asset Management Plc, and the founding shareholder of Eco Hotels UK Plc. Suchit is a mainstream impact investor that takes established products, services, and technology to growth markets such as India. Climate change is a topical issue that he is passionate about, and he is focused on promoting businesses that support sustainability. Suchit’s vision of a chain of carbon net zero hotels rolled out using the modern construction method has resulted in Modulex, which will aid the rapid rollout of hotels across target markets. He is also a founding shareholder of the Company.",
      },
      {
        name: "Mrs. Indira Bhargav",
        title: "Independent Director",
        image: team3,
        description:
          "Ms. Indira Bhargava was the third woman to be a Central Board of Direct Taxes Chairperson. Before assuming the post of CBDT Chairperson, Ms. Bhargava was member (investigation) in the Board. Prior to that, she was also the Chief Income Tax Commissioner in the Mumbai circle. Ms. Bhargava belonged to Indian Revenue Services, 1970 batch.",
      },
      {
        name: "Mr. Rajiv Basrur",
        title: "Independent Director",
        image: team4,
        description: "",
      },
      {
        name: "Mr. Parag Mehta",
        title: "Independent Director",
        image: team5,
        description:
          "Mr. Parag Mehta is a Senior partner of Vinod S. Mehta & Co. With an experience spanning over than 35 years and is a problem solver due to his vast expertise in all aspects of the CA practice. He divides his time between Business Advisory Services and M&A. He represents clients before the Income Tax authorities as well as Investigation Authorities. He also provides services for Family Wealth Planning and succession planning. He is also a passionate traveller and does travel blogging in his free time. Also, he is a movie and a cricket buff.",
      },
    ],
    leadershipTeam: [
      {
        name: "Mr. Akash Bhatia",
        title: "Chief Executive Officer",
        image: team6,
        description:
          "Akash Bhatia, a seasoned hospitality professional with 18 years of industry expertise, has recently assumed the role of CEO at Eco Hotels & Resorts. Prior to this, he served as the Vice President at Amritara Hotels & Resorts, showcasing his strategic leadership. His extensive background includes roles as the Cluster General Manager at Ramee Hotels & Resorts in Bahrain and Regional General Manager at Lemon Tree Hotels. With a proven track record, Akash brings a wealth of experience and a passion for elevating guest experiences to his new position.",
      },
      {
        name: "Mr. Vikram Doshi",
        title: "Chief Financial Officer",
        image: team7,
        description:
          "Vikram is a qualified Chartered Accountant and Company Secretary with a rich experience of over 15 years of experience in finance, accounts, compliance, and audit. In his past assignments he has worked with Ernst and Young, Reliance Capital Limited and Nayara Energy Limited gaining exposure and expertise in the fields of finance, accounts, merger and acquisition, investment banking, taxation, compliances, audits and process improvement.",
      },
    ],
  };

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 2500,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <TopBanner />
      <section className="about-area pat-100 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title"> About Us </h2>
            <div className="section-title-shapes"> </div>
            <div className="section-para text-start mt-5">
              <p>
                <span className="label">
                  THE WORLD’S FIRST CARBON NEUTRAL HOTEL BRAND OF ITS KIND,
                </span>
                It works on three important planks - People, Planet and Profit.
                Its primary aim is to establish standards in the mid segment
                hotels in India. It aims to operate 20,000 plus rooms in a short
                span of ten years. Operating net carbon zero hotels is one of
                the main planks. New built hotels constructed using 3D
                Volumetric Construction Technology, which takes less than one
                year to complete a 100-room hotel and which emits almost 60%
                less CO2, is the main plank of company’s net carbon zero
                objective. Operation of Hotels undertaken under EBOT model of
                the company will help it fulfil its objective.
                <span>
                  Eco Hotels is tailored to meet the needs and expectations of
                  the conscious traveller, both business and leisure.
                </span>
              </p>
            </div>
          </div>
          <div className="my-5">
            <h5 className="text-uppercase fw-bold label">Company Strategy</h5>
            <p className="mt-3">Growth is expected to be driven by:</p>
            <ul>
              <li>Scalability</li>
              <li>Pipeline growth</li>
              <li>Maximising profitability of existing portfolio</li>
            </ul>
            <p>
              Phase I of the strategy is to acquire existing properties and
              convert them into 2- and 3-star hotels under the Eco Hotels
              brands.
            </p>
            <p>
              Phase II is to purchase the land with planning permission and roll
              out new build modular hotels in Tier 2 and Tier 3 cities as
              identified by the team.
            </p>
          </div>

          {/* Key Strengths Section */}
          <div>
            <h5 className="text-uppercase fw-bold mb-3 label">Key Strengths</h5>
            <ul className="list-unstyled d-flex flex-column gap-3">
              <li>
                <span role="img" aria-label="economy">
                  <img src={Logo1} alt="logo1" />
                </span>{" "}
                Exposure to high growth economies
              </li>
              <li>
                <span role="img" aria-label="prospects">
                  <img src={Logo2} alt="logo1" />
                </span>{" "}
                Strong market growth prospects
              </li>
              <li>
                <span role="img" aria-label="local-insight">
                  <img src={Logo3} alt="logo1" />
                </span>{" "}
                Local insight into a market that has high barriers to entry
              </li>
              <li>
                <span role="img" aria-label="development-programme">
                  <img src={Logo4} alt="logo1" />
                </span>{" "}
                Proprietary hotel development programme EBOT delivering turnkey
                hotel development services
              </li>
              <li>
                <span role="img" aria-label="management-team">
                  <img src={Logo5} alt="logo1" />
                </span>{" "}
                Experienced local and global management team
              </li>
              <li>
                <span role="img" aria-label="brands">
                  <img src={Logo6} alt="logo1" />
                </span>{" "}
                Brands From Eco Hotel - The Eco, Eco Satva, EcoValue, Eco Satva
                (Xpress)
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* <section className="history-area pat-50 pab-50">
        <div className="container">
          <div className="section-title-three text-left append-flex">
            <h2 className="title"> We Have A Rich History </h2>
            <div className="append-history"></div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12">
              <div className="history-wrapper">
                <Slider
                  className="global-slick-init history-slider nav-style-one"
                  {...settings}
                >
                  <div className="history-wrapper-item">
                    <div className="single-history center-text">
                      <div className="single-history-inner">
                        <h3 className="single-history-year">
                          {" "}
                          <span className="single-history-year-title">
                            {" "}
                            1982{" "}
                          </span>{" "}
                        </h3>
                        <div className="single-history-thumb">
                          <Link to={about1} className="gallery-popup">
                            {" "}
                            <img src={about1} alt="img" />{" "}
                          </Link>
                        </div>
                        <div className="single-history-contents">
                          <h4 className="single-history-contents-title">
                            {" "}
                            <a href="hotel_detail.html"> Eco Hotel </a>{" "}
                          </h4>
                          <p className="single-history-contents-para">
                            {" "}
                            Fort Lauderdale, Florida{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="history-wrapper-item">
                    <div className="single-history center-text">
                      <div className="single-history-inner">
                        <h3 className="single-history-year">
                          {" "}
                          <span className="single-history-year-title">
                            {" "}
                            1928{" "}
                          </span>{" "}
                        </h3>
                        <div className="single-history-thumb">
                          <Link to={about2} className="gallery-popup">
                            {" "}
                            <img src={about2} alt="img" />{" "}
                          </Link>
                        </div>
                        <div className="single-history-contents">
                          <h4 className="single-history-contents-title">
                            {" "}
                            <a href="hotel_detail.html"> Eco Hotel </a>{" "}
                          </h4>
                          <p className="single-history-contents-para">
                            {" "}
                            Woodland Hills, Oklahoma{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="history-wrapper-item">
                    <div className="single-history center-text">
                      <div className="single-history-inner">
                        <h3 className="single-history-year">
                          {" "}
                          <span className="single-history-year-title">
                            {" "}
                            2021{" "}
                          </span>{" "}
                        </h3>
                        <div className="single-history-thumb">
                          <Link to={about3} className="gallery-popup">
                            {" "}
                            <img src={about3} alt="img" />{" "}
                          </Link>
                        </div>
                        <div className="single-history-contents">
                          <h4 className="single-history-contents-title">
                            {" "}
                            <a href="hotel_detail.html"> Eco Hotel </a>{" "}
                          </h4>
                          <p className="single-history-contents-para">
                            {" "}
                            12 Rue Parnell, 8504 Paris{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="history-wrapper-item">
                    <div className="single-history center-text">
                      <div className="single-history-inner">
                        <h3 className="single-history-year">
                          {" "}
                          <span className="single-history-year-title">
                            {" "}
                            1928{" "}
                          </span>{" "}
                        </h3>
                        <div className="single-history-thumb">
                          <Link to={about2} className="gallery-popup">
                            {" "}
                            <img src={about2} alt="img" />{" "}
                          </Link>
                        </div>
                        <div className="single-history-contents">
                          <h4 className="single-history-contents-title">
                            {" "}
                            <a href="hotel_detail.html"> Eco Hotel </a>{" "}
                          </h4>
                          <p className="single-history-contents-para">
                            {" "}
                            Fort Lauderdale, Florida{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="history-wrapper-item">
                    <div className="single-history center-text">
                      <div className="single-history-inner">
                        <h3 className="single-history-year">
                          {" "}
                          <span className="single-history-year-title">
                            {" "}
                            2021{" "}
                          </span>{" "}
                        </h3>
                        <div className="single-history-thumb">
                          <Link to={about3} className="gallery-popup">
                            {" "}
                            <img src={about3} alt="img" />{" "}
                          </Link>
                        </div>
                        <div className="single-history-contents">
                          <h4 className="single-history-contents-title">
                            {" "}
                            <a href="hotel_detail.html"> Eco Hotel </a>{" "}
                          </h4>
                          <p className="single-history-contents-para">
                            {" "}
                            Fort Lauderdale, Florida{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="history-area pat-50 pab-50 custom-bg-color">
        <div className="container">
          <div className="section-title-three text-left append-flex">
            <h2 className="title"> Our Brands </h2>
            <div className="append-history"></div>
          </div>
          <div className="d-none d-sm-block">
            <div className="row g-4 mt-5 ">
              <div className="col-xl-3 col-sm-7 col-md-7 col-lg-7">
                <div className="sticky-top">
                  <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                    <div className="details-sidebar">
                      <div className="details-sidebar-offer-thumb text-center">
                        <img src={Brand} alt="" height={160} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-7 col-md-7 col-lg-7">
                <div className="sticky-top">
                  <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                    <div className="details-sidebar">
                      <div className="details-sidebar-offer-thumb text-center">
                        <img src={Brand1} alt="" height={160} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-7 col-md-7 col-lg-7">
                <div className="sticky-top">
                  <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                    <div className="details-sidebar">
                      <div className="details-sidebar-offer-thumb text-center">
                        <img src={Brand2} alt="" height={160} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-7 col-md-7 col-lg-7">
                <div className="sticky-top">
                  <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                    <div className="details-sidebar">
                      <div className="details-sidebar-offer-thumb text-center">
                        <img src={Brand3} alt="" height={160} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-7 col-md-7 col-lg-7"></div>
              <div className="col-xl-3 col-sm-7 col-md-7 col-lg-7">
                <div className="sticky-top">
                  <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                    <div className="details-sidebar">
                      <div className="details-sidebar-offer-thumb text-center">
                        <img src={Brand4} alt="" height={160} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-7 col-md-7 col-lg-7">
                <div className="sticky-top">
                  <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                    <div className="details-sidebar">
                      <div className="details-sidebar-offer-thumb text-center">
                        <img src={Brand5} alt="" height={160} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3 d-sm-none">
            <Suspense fallback={<div>Loading...</div>}>
              <Brands />
            </Suspense>
          </div>
        </div>
      </section>

      <section className="history-area pat-50 pab-50">
        <div className="container">
          <div className="section-title-three text-left append-flex">
            <h2 className="title"> We have A Rich History </h2>
            <div className="append-history"></div>
          </div>
          <div className="container mt-5">
            <h4 className="text-uppercase fw-bold label">
              Holding and Ultimate Holding Companies
            </h4>

            {/* Eco Hotels UK Plc */}
            <div className="row mt-5">
              <div className="col-md-2">
                <img
                  src={Rich1}
                  alt="Eco Hotels"
                  className="img-fluid"
                  width={180}
                />
              </div>
              <div className="col-md-10 mt-3 mt-sm-0">
                <h6 className="fw-bold label">Eco Hotels UK Plc</h6>
                <p>
                  Holding company name is{" "}
                  <a href="#!" className="text-gold">
                    Eco Hotels UK Plc
                  </a>{" "}
                  which is based in UK. Holding Company’s objective is to become
                  a leading owner, developer and asset manager of three-star
                  premium and economy brands in the BRICS and N11 economies,
                  with India being the first target market, where the
                  opportunity for growth is the greatest.
                </p>
              </div>
            </div>

            {/* Red Ribbon AMC */}
            <div className="row mt-5">
              <div className="col-md-2">
                <img
                  src={Rich2}
                  alt="Red Ribbon AMC"
                  className="img-fluid"
                  width={180}
                />
              </div>
              <div className="col-md-10">
                <h6 className="fw-bold label mt-3 mt-sm-0">Red Ribbon AMC</h6>
                <p>
                  <a href="#!" className="text-gold">
                    RRAMC
                  </a>{" "}
                  is the ultimate holding company of Eco Hotels India group and
                  direct holding company of Eco Hotels UK Plc. RRAMC is also
                  situated in the UK.
                </p>
              </div>
            </div>

            {/* Our Subsidiaries */}
            <div className="row mt-5">
              <div className="col-md-2 p-3">
                <img
                  src={Rich3}
                  alt="Eco Hotels"
                  className="img-fluid"
                  width={180}
                />
              </div>
              <div className="col-md-10">
                <h6 className="fw-bold label">Our Subsidiaries</h6>
                <p>
                  <a href="#!" className="text-gold">
                    Eco Hotel and Resort Limited
                  </a>{" "}
                  has a subsidiary named{" "}
                  <a href="#!" className="text-gold">
                    Eco Hotels India Private Limited
                  </a>{" "}
                  in which the latter holds more than 99% stake. Both companies
                  operate on an asset-light model.
                </p>
              </div>
            </div>

            {/* Associate Companies */}
            <div className="row mt-5">
              <div className="col-md-2 p-4">
                <img
                  src={Rich4}
                  alt="Modulex"
                  className="img-fluid"
                  width={180}
                />
              </div>
              <div className="col-md-10">
                <h6 className="fw-bold label">Associate Companies:</h6>
                <p>
                  <a href="#!" className="text-gold">
                    Modulex Group
                  </a>{" "}
                  - Modulex Construction Technologies Limited is listed on BSE
                  is affiliated to Moduler Building PLC. Modulex is a pioneer in
                  construction technology, with a steadfast commitment to carbon
                  neutrality and innovation, combined with a determination to
                  harness the power of emerging technologies to meet critical
                  housing and infrastructure needs across the globe, at pace and
                  with optimal efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <TeamSection title="Board Members" teamData={members.boardMembers} />
      <TeamSection title="Leadership Team" teamData={members.leadershipTeam} />
    </>
  );
}

export default AboutUs;
