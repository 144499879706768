import contact1 from "../assets/img/single-page/contact.jpg";
import TopBanner from "./Banner";
import axios from "axios";
import { CheckCircleOutline } from "@mui/icons-material";
import Subscription from "./helper/Subscription";
import { useState } from "react";

function Contact() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSubmitContact = async () => {
    try {
      const res = await axios.post(
        "https://ecohotelsresortslimited.od2.vtiger.com/restapi/vtap/webhook/Contact",
        {
          email: email,
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          description: description,
        },
        {
          headers: {
            Token: "pin01oBcw69hHbmjlyG1ot72",
          },
        }
      );

      if (res) {
        setIsPopupOpen(true);
        setEmail("");
        setFirstName("");
        setLastName("");
        setMobile("");
        setDescription("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isPopupOpen && (
        <Subscription title="Newsletter" onClose={() => setIsPopupOpen(false)}>
          <div style={{ textAlign: "center" }}>
            <CheckCircleOutline style={{ fontSize: "100px", color: "green" }} />
            <h5 style={{ fontSize: "24px", color: "green", margin: "40px" }}>
              SUCCESSFULLY SUBMITTED
            </h5>
          </div>
        </Subscription>
      )}
      <TopBanner />
      <section className="about-area pat-100 pab-20">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title"> Contact Us</h2>
            <div className="section-title-shapes"> </div>
          </div>
        </div>
      </section>
      <section className="contact-area pat-50 pab-50">
        <div className="container">
          <div className="contact-map">
            <iframe src="https://www.openstreetmap.org/export/embed.html?bbox=90.35842895507812%2C23.53377298325595%2C91.505126953125%2C24.090350357613616&amp;layer=mapnik"></iframe>
          </div>
          <div className="row">
            <div className="col-lg-6 mt-5">
              <div className="dashboard-right-contents mt-4 mt-lg-0">
                <div className="dashboard-reservation">
                  <div className="single-reservation bg-white base-padding">
                    <div className="single-reservation-item">
                      <div className="single-reservation-name">
                        <h5 className="single-reservation-name-title color-one">
                          Eco Hotels and Resorts Limited
                        </h5>
                      </div>
                    </div>
                    <div className="single-reservation-item">
                      <div className="single-reservation-details">
                        <div className="single-reservation-details-item">
                          <h5 className="single-reservation-details-title">
                            <i className="las la-map-marker-alt"></i> Address
                          </h5>
                          <span className="single-reservation-details-subtitle">
                            SECOND FLOOR, RAJMAHAL BUILDING, CHURCHGATE, MUMBAI, MAHARASTRA, INDIA - 400062
                          </span>
                        </div>
                        <div className="single-reservation-details-item">
                          <h5 className="single-reservation-details-title">
                            <i className="las la-phone-alt"></i> Mobile No.
                          </h5>
                          <span className="single-reservation-details-subtitle">
                            <a href="tel:+91-9650350602" className="single-reservation-contact-list">
                              +91-9650350602
                            </a> (WhatsApp)
                          </span>
                        </div>
                        <div className="single-reservation-details-item">
                          <h5 className="single-reservation-details-title">
                            <i className="las la-envelope"></i> Email Id
                          </h5>
                          <span className="single-reservation-details-subtitle">
                            <a href="mailto:revenuehead@ecohotels.in" className="single-reservation-contact-list">
                              revenuehead@ecohotels.in
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5">
              <div className="contact-wrapper contact-padding bg-white radius-20">
                <div className="contact-contents">
                  <h4 className="contact-contents-title"> Get in touch </h4>
                  <p className="contact-contents-para mt-2">
                    Our friendly team would love to hear from you.
                  </p>
                  <div className="contact-contents-form custom-form">
                    <div className="single-flex-input mt-4">
                      <div className="single-input mt-4">
                        <input
                          type="text"
                          className="form--control radius-5"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="single-input mt-4">
                        <input
                          type="text"
                          className="form--control radius-5"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="single-input mt-4">
                      <input
                        type="text"
                        className="form--control radius-5"
                        placeholder="Type Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="single-input mt-4">
                      <input
                        type="Phone"
                        className="form--control radius-5"
                        placeholder="Type Number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                    <div className="single-input mt-4">
                      <textarea
                        className="form--control form-message radius-5"
                        placeholder="Your Message"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                    <button type="submit" className="submit-btn radius-5" onClick={handleSubmitContact}>
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
