import React from "react";
import { Link } from "react-router-dom";
import TopBanner from "./Banner";
import Brand from "../assets/img/brand/brand.png";

import RecentNews1 from "../assets/img/news/recent-news-1.webp";
import RecentNews2 from "../assets/img/news/recent-news-2.jpg";
import RecentNew3 from "../assets/img/news/recent-news-3.webp";
import { newsData } from "./UpcomingHotel";

const NewsList = () => {
  return (
    <>
      <TopBanner />

      <div className="container">
        {/* <section className="blog-area pat-70 pab-50">
          <div className="container">
            <div className="section-title center-text">
              <h2 className="title"> Latest News </h2>
              <div className="section-title-shapes"> </div>
            </div>
            <div className="row gy-4 mt-5">
              <div className="col-xxl-4 col-lg-4 col-md-6 p-3">
                <div className="single-blog">
                  <div className="single-blog-thumbs">
                    <Link to="/NewsDetails">
                      {" "}
                      <img className="lazyloads" src={blog1} alt="" />{" "}
                    </Link>
                    <div className="single-blog-thumbs-date">
                      <Link to="javascript:void(0)">
                        {" "}
                        <span className="date"> 18 </span>{" "}
                        <span className="month"> Jun </span>{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="single-blog-contents mt-3">
                    <h4 className="single-blog-contents-title2 mt-3">
                      {" "}
                      <Link to="/NewsDetails">
                        {" "}
                        Matching supply with demand for eco-friendly hospitality{" "}
                      </Link>{" "}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4 col-md-6 p-3">
                <div className="single-blog">
                  <div className="single-blog-thumbs">
                    <Link to="/NewsDetails">
                      {" "}
                      <img className="lazyloads" src={blog2} alt="" />{" "}
                    </Link>
                    <div className="single-blog-thumbs-date">
                      <Link to="javascript:void(0)">
                        {" "}
                        <span className="date"> 19 </span>{" "}
                        <span className="month"> Jun </span>{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="single-blog-contents mt-3">
                    <h4 className="single-blog-contents-title2 mt-3">
                      {" "}
                      <Link to="/NewsDetails">
                        {" "}
                        Guests Have Their Part to Play Too…Sustainable
                        Hospitality{" "}
                      </Link>{" "}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4 col-md-6 p-3">
                <div className="single-blog">
                  <div className="single-blog-thumbs">
                    <Link to="/NewsDetails">
                      {" "}
                      <img className="lazyloads" src={blog3} alt="" />{" "}
                    </Link>
                    <div className="single-blog-thumbs-date">
                      <Link to="javascript:void(0)">
                        {" "}
                        <span className="date"> 20 </span>{" "}
                        <span className="month"> Jun </span>{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="single-blog-contents mt-3">
                    <h4 className="single-blog-contents-title2 mt-3">
                      {" "}
                      <Link to="/NewsDetails">
                        {" "}
                        Taking revenge on Covid: Why more and more travellers.{" "}
                      </Link>{" "}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4 col-md-6 p-3">
                <div className="single-blog">
                  <div className="single-blog-thumbs">
                    <Link to="/NewsDetails">
                      {" "}
                      <img className="lazyloads" src={blog4} alt="" />{" "}
                    </Link>
                    <div className="single-blog-thumbs-date">
                      <Link to="javascript:void(0)">
                        {" "}
                        <span className="date"> 20 </span>{" "}
                        <span className="month"> Jun </span>{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="single-blog-contents mt-3">
                    <h4 className="single-blog-contents-title2 mt-3">
                      {" "}
                      <Link to="/NewsDetails">
                        {" "}
                        An outlier in our post-pandemic world—India is still the
                        go-to destination{" "}
                      </Link>{" "}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4 col-md-6 p-3">
                <div className="single-blog">
                  <div className="single-blog-thumbs">
                    <Link to="/NewsDetails">
                      {" "}
                      <img className="lazyloads" src={blog5} alt="" />{" "}
                    </Link>
                    <div className="single-blog-thumbs-date">
                      <Link to="javascript:void(0)">
                        {" "}
                        <span className="date"> 20 </span>{" "}
                        <span className="month"> Jun </span>{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="single-blog-contents mt-3">
                    <h4 className="single-blog-contents-title2 mt-3">
                      {" "}
                      <Link to="/NewsDetails">
                        {" "}
                        As Diwali burns brightly away …Get ready for the
                        blushing brides{" "}
                      </Link>{" "}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="blog-area  pat-70 pab-100">
          <div className="container">
            <div className="section-title-three append-flex">
              <h2 className="title"> Latest News </h2>
              <div className="append-attraction append-color-two"></div>
            </div>
            <div className="row gy-5 pat-40">
              <div className="col-xl-9 col-lg-9">
                <div className="row gy-5 pat-40">
                  {newsData?.map((item) => (
                    <div className="col-xxl-4 col-lg-4 col-md-6 p-3">
                      <div className="single-blog">
                        <div className="single-blog-thumbs">
                          <img
                            className="lazyloads"
                            src={item.image}
                            alt=""
                            height={180}
                          />

                          <div className="single-blog-thumbs-date">
                            <Link to="javascript:void(0)">
                              <span className="date"> {item.date} </span>{" "}
                              <span className="month"> {item.month} </span>{" "}
                            </Link>
                          </div>
                        </div>
                        <div className="single-blog-contents mt-3">
                          <h4 className="single-blog-contents-title2 mt-3">
                            {" "}
                            <Link to={item.link}>{item.title}</Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row mt-5">
                  <div className="col">
                    <div className="pagination-wrapper">
                      <ul className="pagination-list list-style-none">
                        <li className="pagination-list-item-prev">
                          <Link
                            href="javascript:void(0)"
                            className="pagination-list-item-button"
                          >
                            {" "}
                            Prev{" "}
                          </Link>
                        </li>
                        <li className="pagination-list-item">
                          <Link
                            href="javascript:void(0)"
                            className="pagination-list-item-link"
                          >
                            {" "}
                            1{" "}
                          </Link>
                        </li>
                        <li className="pagination-list-item active">
                          <Link
                            href="javascript:void(0)"
                            className="pagination-list-item-link"
                          >
                            {" "}
                            2{" "}
                          </Link>
                        </li>
                        <li className="pagination-list-item">
                          <Link
                            href="javascript:void(0)"
                            className="pagination-list-item-link"
                          >
                            {" "}
                            3{" "}
                          </Link>
                        </li>
                        <li className="pagination-list-item">
                          <Link
                            href="javascript:void(0)"
                            className="pagination-list-item-link"
                          >
                            {" "}
                            4{" "}
                          </Link>
                        </li>
                        <li className="pagination-list-item">
                          <Link
                            href="javascript:void(0)"
                            className="pagination-list-item-link"
                          >
                            {" "}
                            5{" "}
                          </Link>
                        </li>
                        <li className="pagination-list-item-next">
                          <Link
                            href="javascript:void(0)"
                            className="pagination-list-item-button"
                          >
                            {" "}
                            Next{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3">
                <div className="row gy-5 pat-40">
                  <div className="blog-details-side">
                    <div className="blog-details-side-item radius-10">
                      <div className="blog-details-content">
                        <ul className="recent-list list-style-none">
                          <li class="item-search">
                            <input
                              type="text"
                              class="form--control"
                              placeholder="Search Blog"
                            />
                            <div class="item-search-icon">
                              {" "}
                              <i class="las la-search"></i>{" "}
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="blog-details-side-title open">
                        <h5 className="title"> Recent News </h5>
                        <div className="blog-details-side-inner">
                          <ul className="recent-list list-style-none">
                            <li className="recent-list-item">
                              <div className="recent-list-thumb">
                                <a href="#0">
                                  {" "}
                                  <img
                                    className="img-fluid radius-5"
                                    style={{ height: "60px", width: "90px" }}
                                    src={RecentNews1}
                                    alt=""
                                  />{" "}
                                </a>
                              </div>
                              <div className="recent-list-contents">
                                <h6 className="recent-list-title fs-16">
                                  {" "}
                                  <a href="#0">
                                    {" "}
                                    Hotel Market Recovery: A Global Perspective
                                    by CBRE{" "}
                                  </a>{" "}
                                </h6>
                                <span className="recent-list-dates light-color fs-14 mt-2">
                                  {" "}
                                  21 Jun 2022{" "}
                                </span>
                              </div>
                            </li>
                            <li className="recent-list-item">
                              <div className="recent-list-thumb">
                                <a href="#0">
                                  {" "}
                                  <img
                                    className="img-fluid radius-5"
                                    style={{ height: "60px", width: "90px" }}
                                    src={RecentNews2}
                                    alt=""
                                  />{" "}
                                </a>
                              </div>
                              <div className="recent-list-contents">
                                <h6 className="recent-list-title fs-16">
                                  {" "}
                                  <a href="#0">
                                    {" "}
                                    Oxford Economics - Future Of Construction
                                    2030{" "}
                                  </a>{" "}
                                </h6>
                                <span className="recent-list-dates light-color fs-14 mt-2">
                                  {" "}
                                  22 Jun 2022{" "}
                                </span>
                              </div>
                            </li>
                            <li className="recent-list-item">
                              <div className="recent-list-thumb">
                                <a href="#0">
                                  {" "}
                                  <img
                                    className="img-fluid radius-5"
                                    style={{ height: "60px", width: "90px" }}
                                    src={RecentNew3}
                                    alt=""
                                  />{" "}
                                </a>
                              </div>
                              <div className="recent-list-contents">
                                <h6 className="recent-list-title fs-16">
                                  {" "}
                                  <a href="#0">
                                    {" "}
                                    McKinsey & Company: Private markets rally to
                                    new heights{" "}
                                  </a>{" "}
                                </h6>
                                <span className="recent-list-dates light-color fs-14 mt-2">
                                  {" "}
                                  24 Jun 2022{" "}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NewsList;
