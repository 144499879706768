import reward1 from '../assets/img/reward/InfinityTierCardsSilver.jpg';
import reward2 from '../assets/img/reward/InfinityTierGold.jpg';
import reward3 from '../assets/img/reward/InfinityTierPlatinum.jpg';
import TopBanner from './Banner';



function RewardProgram() {
  return (
    <>
    <TopBanner />
      <section className="hotel-details-area section-bg-2 pat-100 pab-100">
        <div className="container">
            <div className="row g-4">
                
                <div className="col-xl-4 col-lg-4">
                    <div className="sticky-top">
                        <div className="hotel-details-widget widget bg-white radius-10">
                            <div className="hotel-view">
                                <div className="hotel-view-thumb hotel-view-grid-thumb bg-image">
                                <a href="#" className="gallery-popup-two"> <img src={reward1} alt="img" /> </a>
                                </div>
                                <div className="hotel-view-contents">
                                    <div className="hotel-view-contents-header">
                                        <h3 className="hotel-view-contents-title"> As soon as you join </h3>
                                        <div className="hotel-view-contents-location mt-2">
                                            <ul>
                                                <li>Member's rate</li>
                                                <li>Special offer</li>
                                                <li>Free Wifi</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                    <div className="sticky-top">
                        <div className="hotel-details-widget widget bg-white radius-10">
                            <div className="hotel-view">
                                <div className="hotel-view-thumb hotel-view-grid-thumb bg-image">
                                <a href="#" className="gallery-popup-two"> <img src={reward2} alt="img" /> </a>
                                </div>
                                <div className="hotel-view-contents">
                                    <div className="hotel-view-contents-header">
                                    <h3 className="hotel-view-contents-title"> From 1500 Infinity coins </h3>
                                        <div className="hotel-view-contents-location mt-2">
                                            <ul>
                                                <li>All the benefits of Silver Tier, And</li>
                                                <li>Extra Bonus on Infinity Coins Earned</li>
                                                <li>Complimentary Room Upgrade</li>
                                                <li>Flexible Cancellation</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                    <div className="sticky-top">
                        <div className="hotel-details-widget widget bg-white radius-10">
                            <div className="hotel-view">
                                <div className="hotel-view-thumb hotel-view-grid-thumb bg-image">
                                <a href="#" className="gallery-popup-two"> <img src={reward3} alt="img" /> </a>
                                </div>
                                <div className="hotel-view-contents">
                                <div className="hotel-view-contents-header">
                                    <h3 className="hotel-view-contents-title"> From 3000 Infinity Coins </h3>
                                        <div className="hotel-view-contents-location mt-2">
                                            <ul>
                                                <li>All the benefits of Gold tier, And</li>
                                                <li>2nd Guest Stays Free</li>
                                                <li>2 piece of laundry per stay</li>
                                                <li>Early checkin - late check out</li>
                                                <li>48 hrs Room Guarantee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default RewardProgram;
