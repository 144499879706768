import Hotel1 from "../../assets/img/sindhudurg/hotel/hotel1.webp";
import Hotel2 from "../../assets/img/sindhudurg/hotel/hotel2.webp";
import Hotel3 from "../../assets/img/sindhudurg/hotel/hotel3.webp";
import Hotel4 from "../../assets/img/sindhudurg/hotel/hotel4.webp";
import Hotel5 from "../../assets/img/sindhudurg/hotel/hotel5.webp";
import Hotel6 from "../../assets/img/sindhudurg/hotel/hotel6.webp";
import Hotel7 from "../../assets/img/sindhudurg/hotel/hotel7.webp";
import Hotel8 from "../../assets/img/sindhudurg/hotel/hotel8.webp";

import { Link } from "react-router-dom";

export const diningRestaurant = (
  <>
    <p>
      Darbar, the main restaurant at Eco Xpress Sindhudurg, offers a regal
      dining experience, inspired by the rich cultural heritage of the region.
      The restaurant serves a curated menu featuring a mix of traditional
      Malvani cuisine and global dishes, made from fresh, locally-sourced
      ingredients.
    </p>
    <br />
    <h6 className="label mt-2">Timings</h6>
    <div className="row align-items-center px-md-3 mt-2">
      <div className="col-2 col-md-2">
        <p className="label">Breakfast:</p>
        <p className="label">Lunch:</p>
        <p className="label">Dinner:</p>
      </div>
      <div className="col-6 col-md-6 ">
        <p className="text-gold">7:00 AM – 10:30 AM</p>
        <p className="text-gold">12:00 PM – 3:00 PM</p>
        <p className="text-gold">7:00 PM – 11:00 PM</p>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content-end btn-wrapper par-20 mt-3 mt-md-0 order-last order-md-0">
        <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link>
      </div>
    </div>
  </>
);

export const diningBarMenu = (
  <>
    <p>
      GG's Rooftop Restobar is the perfect blend of modern luxury and serene
      coastal vibes, offering a relaxing atmosphere with breathtaking views of
      Sindhudurg’s lush greenery and coastline. Situated atop the hotel, it
      provides an open-air dining experience with a diverse menu of
      non-alcoholic beverages, signature mocktails, and gourmet snacks.
    </p>
    <br />
    <div className="d-flex justify-content-between align-items-end">
      <div>
        <h6 className="label">Timings</h6>
        <p className="label mt-2">
          Everyday: <span className="text-gold">5:00 PM – 12:00 AM</span>
        </p>
      </div>
      <div className="btn-wrapper par-20">
        <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
      </div>
    </div>
  </>
);


export const sindhudurgHotelImageList = [
  { id: 1, image: Hotel1 },
  { id: 2, image: Hotel2 },
  { id: 3, image: Hotel3 },
  { id: 4, image: Hotel4 },
  { id: 5, image: Hotel5 },
  { id: 6, image: Hotel6 },
  { id: 7, image: Hotel7 },
  { id: 8, image: Hotel8 },
];
