function calculateDaysBetweenDates(date1, date2) {
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();

  // Calculate the difference in milliseconds
  const diffMs = Math.abs(date2Ms - date1Ms);

  // Convert milliseconds to days
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const daysDiff = Math.round(diffMs / millisecondsPerDay);

  return daysDiff;
}

export { calculateDaysBetweenDates };
