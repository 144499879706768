import { configureStore } from "@reduxjs/toolkit";
import searchHotelReducer from "./reducer/searchHotelReducer";
import bookingHotelReducer from "./reducer/bookingHotelReducer";

const store = configureStore({
  reducer: {
    search: searchHotelReducer,
    booking: bookingHotelReducer,
  },
});

export default store;
