import React, { useEffect, useRef, useState } from "react";
import SearchBox from "../SearchBox";
import Slider from "react-slick";
import hoteld1 from "../../../assets/img/sindhudurg/nearby/img1.png";
import hoteld2 from "../../../assets/img/sindhudurg/nearby/img2.png";
import hoteld3 from "../../../assets/img/sindhudurg/nearby/img3.png";
import food1 from "../../../assets/img/sindhudurg/nearby/food1.png";
import food2 from "../../../assets/img/sindhudurg/nearby/food2.png";
import food3 from "../../../assets/img/sindhudurg/nearby/food3.png";
import food4 from "../../../assets/img/sindhudurg/nearby/food4.png";
import culture from "../../../assets/img/sindhudurg/nearby/culture.png";
import fort from "../../../assets/img/sindhudurg/nearby/fort.png";
import fort2 from "../../../assets/img/sindhudurg/nearby/fort2.png";
import temple from "../../../assets/img/sindhudurg/nearby/temple.png";
import Malvan from "../../../assets/img/sindhudurg/nearby/malvan.png";
import Beach1 from "../../../assets/img/sindhudurg/nearby/beach1.png";
import Beach2 from "../../../assets/img/sindhudurg/nearby/beach2.png";
import Beach3 from "../../../assets/img/sindhudurg/nearby/beach3.png";
import Waterfall from "../../../assets/img/sindhudurg/nearby/waterfall.png";
import travel from "../../../assets/img/sindhudurg/nearby/travel.png";
import kochiPalace from "../../../assets/img/kochi/nearby/palace.png";
import Sanctuary from "../../../assets/img/kochi/nearby/sacn.png";
import Lake from "../../../assets/img/kochi/nearby/lake.png";
import sunnySvg from "../../../assets/img/hotel/sunny.svg";
import rainySvg from "../../../assets/img/hotel/rainy.svg";
import weather from "../../../assets/img/kota/nearby/weather.png";
import snowySvg from "../../../assets/img/hotel/weather_snowy.png";
import HotelTab from "../HotelTab";

const cultureContent = (
  <>
    <p>
      Sindhudurg is steeped in Maratha history and is known for its strong
      cultural identity, which is reflected in its traditional festivals, local
      music, and vibrant art forms. The people are welcoming, and the local
      culture is deeply tied to the sea, with fishing being a major livelihood.
      Malvani is the local language, and the district is famous for its
      traditional Malvani cuisine.
    </p>
    <br />
    <p>
      Sindhudurg, a district in Maharashtra's Konkan region, has a population of
      approximately 8.6 lakh people. The population is spread across several
      towns and villages, with a mix of urban and rural communities.
    </p>
  </>
);

const travelContent = (
  <>
    <ul className="d-flex flex-column gap-4">
      <li>
        <span className="label">By Air:</span>The nearest airport is Goa
        International Airport, about 130 km from Sindhudurg. It takes around 3
        hours by road.
      </li>
      <li>
        <span className="label">By Train:</span>Sindhudurg is well connected by
        the Konkan Railway, with Kudal and Sindhudurg stations nearby, providing
        access from major cities like Mumbai and Goa.
      </li>
      <li>
        <span className="label">By Road:</span>Sindhudurg is accessible by road
        via the Mumbai-Goa Highway. State transport and private taxis are
        readily available for local travel.
      </li>
    </ul>
  </>
);

const SindhudurgNearbyAttraction = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  const divRefs = useRef([]);

  const sections = [
    {
      id: 2,
      label: "Cuture",
      image: culture,
      type: "dining",
      data: [{ content: cultureContent }],
    },
    {
      id: 3,
      label: "Travel",
      image: travel,
      type: "event",
      data: [{ content: travelContent }],
    },
    { id: 4, label: "Food" },
  ];

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <divasa className="next-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-right"></i>
      </divasa>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-left"></i>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <section className="hotel-details-area section-bg-2 pat-120 pab-50">
      <div className="container">
        <SearchBox />
        <div className="details-contents bg-white radius-10 mt-4">
          <div className="details-contents-header">
            <div className="main">
              <Slider
                asNavFor={nav2}
                ref={(slider) => (sliderRef1 = slider)}
                arrows={false}
                className="slider slide-show"
              >
                <div className="bg-image radius-5">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld1}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld2}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld3}
                    alt=""
                  />
                </div>
              </Slider>
              <Slider
                asNavFor={nav1}
                ref={(slider) => (sliderRef2 = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                {...settings}
                className="global-slick-init attraction-slider nav-style-one nav-color-one"
              >
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld1}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld2}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld3}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Slider>
            </div>

            <div className="hotel-view-contents">
              <div className="hotel-view-contents-header">
                <h3 className="hotel-view-contents-title fw-bold">
                  Explore the Serene Sindhudurg,Maharashtra
                </h3>
                <div className="mt-2">
                  <span className="fw-bold text-secondary">
                    Best Time to Visit Sindhudurg
                  </span>
                  <div className="mt-1 d-flex align-items-center gap-3 label">
                    <img src={weather} alt="sunny" />{" "}
                    <span>October - February</span>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center gap-5">
                  <div>
                    <img src={sunnySvg} alt="sunny" />{" "}
                    <span>Apr - Jun (35°C to 45°C) </span>
                  </div>
                  <div>
                    <img src={rainySvg} alt="sunny" />{" "}
                    <span>Jul - Sep (25°C to 35°C) </span>
                  </div>
                  <div>
                    <img src={snowySvg} alt="sunny" />{" "}
                    <span>Oct - Mar (10°C to 25°C) </span>
                  </div>
                </div>
                <hr />
              </div>

              <div className="hotel-view-contents-bottom">
                <div className="pat-30 par-20">
                  <h5 className="fw-bold label">About</h5>
                  <p className="pat-5">
                    Sindhudurg, located in the Konkan region of Maharashtra,
                    India, is a coastal district renowned for its picturesque
                    landscapes, rich history, and vibrant local culture. With a
                    population of approximately 8.6 lakh people, Sindhudurg
                    boasts a tranquil ambiance and a strong sense of community.
                    <br />
                    <br /> The district experiences a tropical climate,
                    characterized by a hot and humid summer, a refreshing
                    monsoon season with heavy rainfall, and mild, pleasant
                    winters. The weather in Sindhudurg is particularly favorable
                    from October to February, making it an ideal time for
                    travelers to explore its beautiful beaches, historic forts,
                    and lush green hills.
                  </p>
                </div>
                {/* Top Navigation */}
                <ul className="nav nav-pills my-4 gap-4 d-flex justify-content-center">
                  {sections?.map((item, index) => (
                    <li className="nav-item" key={item?.id}>
                      <div className="">
                        <span
                          className={`hotel-btn-wrapper hotel-btn ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => scrollToDiv(index)}
                        >
                          {item?.label}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
                <div>
                  {sections
                    .map((section, index) => (
                      <div
                        key={section.id}
                        ref={(el) => (divRefs.current[index] = el)}
                      >
                        <HotelTab
                          title={section.label}
                          image={section.image}
                          type={section.type}
                          data={section.data}
                        />
                      </div>
                    ))
                    ?.slice(0, 2)}
                  <div ref={(el) => (divRefs.current[2] = el)}>
                    <h4
                      className="hotel-view-contents-bottom-title"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      Food in Kochi
                    </h4>
                    <p>
                      The local cuisine, known as Malvani, is renowned for its
                      seafood, rich flavors, and use of coconut and spices.
                    </p>

                    <div className="pat-10">
                      <div className="row g-4">
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food1}
                                    alt="Appam with Stew"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Fish Curry</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food2}
                                    alt="Meen Moilee"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Sol Kadhi</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food3}
                                    alt="Parotta with Beef Fry"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Kombdi Vade</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food4}
                                    alt="puttu"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Modak</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pat-40">
                      <h4
                        className="hotel-view-contents-bottom-title"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Top Attractions Near The Eco Sindhudurg
                      </h4>

                      <div className="pat-40">
                        <div className="row mb-4">
                          <div className="col-md-4 d-flex justify-content-center">
                            <img src={fort} className="radius-10" alt="Room" />
                          </div>
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Sindhudurg Fort</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Built by Chhatrapati Shivaji Maharaj in
                                    1664, Sindhudurg Fort is an impressive sea
                                    fort situated on an island off the coast.
                                    The fort features massive walls, several
                                    bastions, and hidden tunnels. It offers
                                    panoramic views of the Arabian Sea and the
                                    surrounding landscape. Historical tours and
                                    boat rides to the fort are popular among
                                    visitors.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 8 km</p>
                                  <p>Travel Time: 20 minutes by car</p>
                                </div>
                              </div>
                            </div>

                            <div
                              className="hide-scrollbar"
                              style={{
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "3rem",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Konkeshwar Temple</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    This historic temple, dedicated to Lord
                                    Shiva, is located on the shores of the
                                    Arabian Sea. It’s known for its beautiful
                                    location and traditional architecture. The
                                    temple is an important religious site and a
                                    great spot for spiritual reflection.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 40km</p>
                                  <p>Travel Time: 60 minutes by car</p>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={temple}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={Malvan}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Malvan</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Malvan is a charming town known for its
                                    local Malvani cuisine and cultural heritage.
                                    Visitors can explore the local markets,
                                    taste fresh seafood, and enjoy traditional
                                    dishes. The town also has several quaint
                                    beaches and temples, adding to its appeal.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 10 km</p>
                                  <p>Travel Time: 30 minutes by car</p>
                                </div>
                              </div>
                            </div>

                            <div></div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Jaigad Fort</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Jaigad Fort is a coastal fort located in the
                                    Ratnagiri district of Maharashtra, India.
                                    Perched on a cliff overlooking the Arabian
                                    Sea and the Shastri River estuary, the fort
                                    is strategically positioned to guard the
                                    coastal region and has significant
                                    historical importance.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 120 km</p>
                                  <p>Travel Time: 3 hours by car</p>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <img src={fort2} className="radius-10" alt="Room" />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={Beach1}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Devbagh Beach</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Devbagh Beach, located near Tarkarli in the
                                    Konkan region of Maharashtra, is a serene
                                    and less crowded beach known for its
                                    pristine beauty and golden sands. Set at the
                                    confluence of the Karli River and the
                                    Arabian Sea, it offers visitors a peaceful
                                    retreat with stunning views of the
                                    backwaters and the ocean.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 20 km</p>
                                  <p>Travel Time: 40 minutes by car</p>
                                </div>
                              </div>
                            </div>

                            <div></div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Tsunami Island Vengurla</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Tsunami Island, located near Vengurla in the
                                    Sindhudurg district, is a small, scenic
                                    island formed after the 2004 tsunami, hence
                                    the name. It has become a popular
                                    destination for its calm waters, making it
                                    ideal for water sports like jet skiing,
                                    kayaking, and parasailing. The island
                                    emerges during low tide and offers
                                    picturesque views of the surrounding Karli
                                    River backwaters, mangroves, and lush
                                    greenery, providing a peaceful and
                                    fun-filled escape for nature lovers and
                                    adventure enthusiasts.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 25 km</p>
                                  <p>Travel Time: 45 minutes by car</p>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={Beach2}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={Beach3}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Tarkali Beach</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Tarkarli Beach, located on the Konkan coast
                                    of Maharashtra, is renowned for its
                                    crystal-clear waters, soft white sand, and
                                    tranquil surroundings. It offers a perfect
                                    blend of natural beauty and adventure,
                                    making it a popular destination for both
                                    relaxation and water sports enthusiasts.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 15 km</p>
                                  <p>Travel Time: 30 minutes by car</p>
                                </div>
                              </div>
                            </div>

                            <div></div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Amboli Waterfall</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Amboli Waterfalls is a stunning cascade
                                    located in the hill station of Amboli in
                                    Maharashtra's Sahyadri Hills. Known for its
                                    lush green surroundings, mist-covered
                                    landscapes, and abundant monsoon rains, the
                                    waterfalls offer a breathtaking sight,
                                    especially during the rainy season when they
                                    are at their fullest. The area is also rich
                                    in biodiversity, attracting nature lovers
                                    and photographers.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 70 km</p>
                                  <p>Travel Time: 2 hours by car</p>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={Waterfall}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SindhudurgNearbyAttraction;
