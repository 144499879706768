import React, { useRef, useState } from "react";

import event1 from "../assets/img/events/image1.webp";
import event2 from "../assets/img/events/image2.webp";
import event3 from "../assets/img/events/image3.png";
import event4 from "../assets/img/events/image4.png";
import event5 from "../assets/img/events/image5.png";
import UserForm from "./BookingForm/UserForm";
import HotelBanner from "./Hotel/HotelBanner";
import EventBannerImage from "../assets/img/banner/event-banner.png";
import EventBannerMobile from "../assets/img/banner/event-banner-mobile.png";

const NearbyAttraction = () => {
  const divRefs = useRef([]);

  // Handle Popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <HotelBanner image={EventBannerImage} image_mobile={EventBannerMobile} type="event"/>
      <section className="hotel-details-area section-bg-2 pat-100 pab-50">
        <div className="container">
          <div className="details-contents bg-white radius-10 mt-1">
            <div className="details-contents-header">
              <div className="hotel-view-contents">
                <div className="hotel-view-contents-bottom">
                  <div className="pat-30 par-20">
                    <h4 className="fw-bold label">
                      Personalised, unforgettable events.
                    </h4>
                  </div>
                  {/* Top Navigation */}
                  <div>
                    <div ref={(el) => (divRefs.current[2] = el)}>
                      <div className="pat-40">
                        <div className="row mb-5">
                          <div className="col-md-5 d-flex justify-content-center">
                            <img
                              src={event1}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                          <div className="col-md-7 d-flex flex-column justify-content-between pt-2">
                            {/* Content Area */}
                            <div>
                              <div>
                                <div>
                                  <p className="mb-3">
                                    Eco Hotels offers personalized event
                                    solutions with a focus on sustainability,
                                    ensuring that every gathering, whether
                                    intimate or grand, aligns with
                                    environmentally responsible practices. By
                                    incorporating eco-friendly materials,
                                    energy-efficient lighting, and minimal waste
                                    policies, each event is designed to reduce
                                    its environmental footprint. From corporate
                                    conferences, weddings, and private parties
                                    to themed celebrations, the Eco Hotels’
                                    venues provide customizable spaces equipped
                                    with modern amenities, all while
                                    prioritizing sustainability. The hotel's
                                    banquet halls, open-air venues, and rooftop
                                    spaces are perfect for a variety of events,
                                    offering guests a luxurious yet
                                    eco-conscious experience tailored to their
                                    needs.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              className="hide-scrollbar"
                              style={{
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "3rem",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="hotel-view-contents pb-4">
                          <h3 className="hotel-view-contents-title fw-bold label">
                            Events
                          </h3>
                          <hr />
                        </div>
                        <div className="row mb-5">
                          <div className="col-md-5 d-flex justify-content-center">
                            <img
                              src={event2}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                          <div className="col-md-7 d-flex flex-column justify-content-between pt-2">
                            {/* Content Area */}
                            <div>
                              <div>
                                <div className="hotel-view-contents-flex pb-3">
                                  <h4 className="fw-bold hotel-view-contents label">
                                    Conference Hall
                                  </h4>
                                </div>
                                <div>
                                  <p className="mb-3">
                                    The banquet halls at Eco Hotels provide a
                                    perfect venue for hosting conferences and
                                    meetings, offering state-of-the-art
                                    facilities combined with eco-friendly
                                    practices. Equipped with modern audiovisual
                                    systems, high-speed Wi-Fi, and comfortable
                                    seating, the halls cater to both small and
                                    large corporate gatherings. For conferences,
                                    we can arrange custom layouts, professional
                                    tech support, catering services featuring
                                    organic and locally-sourced ingredients, and
                                    green event management to ensure a minimal
                                    environmental impact. This makes Eco Hotels
                                    a prime choice for businesses looking to
                                    host impactful events while maintaining an
                                    eco-conscious approach.
                                  </p>
                                  <div class="d-flex justify-content-start align-item-center gap-3">
                                    <div class="btn-wrapper">
                                      <span
                                        class="cmn-btn btn-bg-1 btn-small"
                                        onClick={handleOpenPopup}
                                      >
                                        Book Now
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="hide-scrollbar"
                              style={{
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "3rem",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-5">
                          <div className="col-md-7 d-flex flex-column justify-content-between pt-2">
                            {/* Content Area */}
                            <div>
                              <div>
                                <div className="hotel-view-contents-flex pb-3">
                                  <h4 className="hotel-view-contents-title fw-bold label">
                                    Board Room
                                  </h4>
                                </div>
                                <div>
                                  <p className="mb-2x">
                                    The boardrooms at Eco Hotels are designed to
                                    offer a sophisticated and eco-friendly
                                    environment for professional meetings.
                                    Equipped with the latest audiovisual
                                    technology, high-speed Wi-Fi, and ergonomic
                                    seating, the boardroom provides everything
                                    needed for productive discussions.
                                  </p>
                                  <p className="mb-3">
                                    For meetings, eco-friendly arrangements
                                    include offering locally-sourced
                                    refreshments, biodegradable stationery, and
                                    water-saving fixtures. These thoughtful
                                    touches ensure that each meeting maintains a
                                    high level of professionalism while
                                    minimizing its environmental impact, making
                                    it the perfect space for businesses that
                                    prioritize sustainability.
                                  </p>
                                  <div class="d-flex justify-content-start align-item-center gap-3">
                                    <div class="btn-wrapper">
                                      <span
                                        class="cmn-btn btn-bg-1 btn-small"
                                        onClick={handleOpenPopup}
                                      >
                                        Book Now
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="hide-scrollbar"
                              style={{
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "3rem",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="col-md-5 d-flex justify-content-center">
                            <img
                              src={event3}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                        </div>

                        <div className="row mb-5">
                          <div className="col-md-5 d-flex justify-content-center">
                            <img
                              src={event4}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                          <div className="col-md-7 d-flex flex-column justify-content-between pt-2">
                            {/* Content Area */}
                            <div>
                              <div>
                                <div className="hotel-view-contents-flex pb-3">
                                  <h4 className="hotel-view-contents-title fw-bold label">
                                    Weddings
                                  </h4>
                                </div>
                                <div>
                                  <p className="mb-2">
                                    At Eco Hotels, weddings are celebrated with
                                    a blend of luxury and sustainability,
                                    offering personalized services that cater to
                                    every couple's unique vision. The hotel’s
                                    spacious banquet halls create the perfect
                                    backdrop for both intimate and grand
                                    ceremonies.
                                  </p>
                                  <p className="mb-2">
                                    What sets Eco Weddings apart is the emphasis
                                    on eco-friendly
                                    arrangements—energy-efficient lighting,
                                    biodegradable decor, and locally-sourced,
                                    organic catering that supports the
                                    environment without compromising on
                                    elegance.
                                  </p>
                                  <p className="mb-3">
                                    Eco Hotels ensures that every detail of the
                                    event is both luxurious and environmentally
                                    responsible, making it an ideal choice for
                                    couples who want a beautiful yet
                                    earth-friendly celebration.
                                  </p>
                                  <div class="d-flex justify-content-start align-item-center gap-3">
                                    <div class="btn-wrapper">
                                      <span
                                        class="cmn-btn btn-bg-1 btn-small"
                                        onClick={handleOpenPopup}
                                      >
                                        Book Now
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="hide-scrollbar"
                              style={{
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "3rem",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-md-7 d-flex flex-column justify-content-between pt-2">
                            {/* Content Area */}
                            <div>
                              <div>
                                <div className="hotel-view-contents-flex pb-3">
                                  <h4 className="hotel-view-contents-title fw-bold label">
                                    Parties
                                  </h4>
                                </div>
                                <div>
                                  <p className="mb-2">
                                    Eco Hotels offers a vibrant and sustainable
                                    setting for hosting a wide variety of
                                    parties, including birthdays, anniversaries
                                    and social gatherings.
                                  </p>
                                  <p className="mb-3">
                                    Arrangements at Eco Hotels prioritize
                                    eco-friendly elements, such as
                                    energy-efficient lighting, sustainable decor
                                    made from natural or recyclable materials,
                                    and locally-sourced, organic catering
                                    options. Digital invitations and
                                    waste-reduction strategies are also
                                    encouraged to minimize the environmental
                                    impact. With personalized event planning,
                                    customizable menus, and modern amenities,
                                    Eco Hotels provides a luxurious and
                                    eco-conscious environment for unforgettable
                                    celebrations.
                                  </p>
                                  <div class="d-flex justify-content-start align-item-center gap-3">
                                    <div class="btn-wrapper">
                                      <span
                                        class="cmn-btn btn-bg-1 btn-small"
                                        onClick={handleOpenPopup}
                                      >
                                        Book Now
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="hide-scrollbar"
                              style={{
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "3rem",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="col-md-5 d-flex justify-content-center">
                            <img
                              src={event5}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isPopupOpen && <UserForm onClose={handleClosePopup}></UserForm>}
      </section>
    </>
  );
};

export default NearbyAttraction;
