import React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import the icon

const theme = createTheme();

const steps = ['Event Details', 'Personal Details', 'Summary', 'Success'];

const Success = ({ setIsPopupOpen }) => {
  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the popup
    window.location.reload(); // Reload the page
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open fullWidth maxWidth='sm'>
        <AppBar position="static">
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" style={{ color: 'white' }}>
              Success
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ padding: 16, textAlign: 'center' }}>
          {/* Add the CheckCircleIcon */}
          
          
          <Stepper activeStep={3} alternativeLabel style={{ marginBottom: '40px', marginTop: '20px' }}>
            {steps.map((label, index) => (
              <Step key={index} completed={index <= 3}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <CheckCircleIcon style={{ fontSize: '100px', color: 'green', marginBottom: '20px' }} />
          <h5>Thank You For Your Submission</h5>
          <p>You will get an email with further instructions.</p>
          <Button 
            variant="contained"
            color="primary"
            onClick={handleClosePopup}
            style={{ marginTop: '40px', marginBottom:'20px' }}
          >
            Close
          </Button>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default Success;
